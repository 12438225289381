// src/components/InfoCenter.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Home, Mail } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import AdBanner from './AdBanner';
import BlogPost from './BlogPost';
import { blogPostData } from './BlogPostData';

const MEDIA_BASE_URL = 'https://media.satoshiadvance.com/images';

function InfoCenter() {
  const { articleSlug } = useParams();
  useEffect(() => {
    if (articleSlug) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [articleSlug]);

  const navigate = useNavigate();


  // Desktop vs. mobile
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Currently selected article, or null
  const currentArticle = articleSlug ? blogPostData[articleSlug] : null;

  // Default tab state
  const [selectedOption, setSelectedOption] = useState('News & Blogs');

  // Prepare the normal “News & Blogs” items
  const newsAndBlogs = [
    ...Object.values(blogPostData).map(post => ({
      id: post.id,
      title: post.title,
      date: post.date,
      tagline: post.category,
      quote: post.mainQuote,
      slug: post.slug,
      image: post.headerImage,
      isBlogPost: true
    })),
    {
      // Extra “Market Analysis” item if you still want it
      image: `${MEDIA_BASE_URL}/RetroNews2.png`,
      title: 'Market Analysis & Trends',
      quote: "Understanding today's market shapes tomorrow's opportunities.",
      content: 'Deep dive into market trends, expert analysis...',
      isBlogPost: false
    }
  ];

  // Other section data
  const sections = {
    'News & Blogs': newsAndBlogs,
    'Top Loans': [
      {
        image: `${MEDIA_BASE_URL}/RetroLoans1.png`,
        title: 'Best Lending Platforms',
        quote: 'Compare and choose the right platform for your needs.',
        content: 'Discover top-rated cryptocurrency lending platforms...'
      },
      {
        image: `${MEDIA_BASE_URL}/RetroLoans2.png`,
        title: 'Featured Loan Opportunities',
        quote: 'Maximize your crypto lending potential.',
        content: 'Explore current lending opportunities across various platforms.'
      }
    ],
    'Top Links': [
      {
        image: `${MEDIA_BASE_URL}/RetroLinks1.png`,
        title: 'Essential Resources',
        quote: 'Your gateway to crypto lending excellence.',
        content: 'Access our carefully selected collection of links...'
      },
      {
        image: `${MEDIA_BASE_URL}/RetroLinks2.png`,
        title: 'Community Favorites',
        quote: 'Trusted by the crypto community.',
        content: 'Discover community-recommended platforms and tools.'
      }
    ],
    'Resources': [
      {
        image: `${MEDIA_BASE_URL}/RetroResources1.png`,
        title: 'Educational Materials',
        quote: 'Knowledge is your greatest asset.',
        content: 'Access comprehensive guides, tutorials, and educational resources.'
      },
      {
        image: `${MEDIA_BASE_URL}/RetroResources2.png`,
        title: 'Tools & Calculators',
        quote: 'Make informed decisions with powerful tools.',
        content: 'Utilize our suite of tools and calculators.'
      }
    ]
  };

  // Bottom images for each tab
  const dynamicImage = {
    'News & Blogs': `${MEDIA_BASE_URL}/RetroNews3.png`,
    'Top Loans': `${MEDIA_BASE_URL}/RetroLoans3.png`,
    'Top Links': `${MEDIA_BASE_URL}/RetroLinks3.png`,
    'Resources': `${MEDIA_BASE_URL}/RetroResources3.png`
  };

  // If an article is clicked, we want to show it first, then the rest
  const filteredNews = currentArticle
    ? sections['News & Blogs'].filter(item => item.slug !== articleSlug)
    : sections['News & Blogs'];

  // Navigate from a card to a full article
  const handleArticleClick = slug => {
    navigate(`/info-center/${slug}`);
  };

  // Return to homepage
  const handleHomeClick = () => {
    window.location.href = '/';
  };

  // Renders the sticky top bar
  function HeaderBar() {
    return (
      <nav
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 50,
          width: '100%',
          backgroundColor: isDesktop ? '#f5f5f5' : '#fff',
          padding: isDesktop ? '0' : '0 1rem'
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: isDesktop ? '1000px' : '100%',
            margin: isDesktop ? '0 auto 2rem' : '0 0 2rem 0',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: isDesktop ? '2rem' : '1rem',
            backgroundColor: 'white',
            padding: isDesktop ? '1rem 2rem' : '0.75rem',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            justifyContent: isDesktop ? 'center' : 'flex-start'
          }}
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              padding: isDesktop ? '0.75rem 1rem' : '0.5rem 1rem',
              borderRadius: '8px',
              backgroundColor: '#f8f9fa'
            }}
            onClick={handleHomeClick}
          >
            <Home size={isDesktop ? 20 : 18} />
            <span style={{ fontWeight: '500' }}>Home</span>
          </motion.div>

          {Object.keys(sections).map(option => (
            <motion.div
              key={option}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                cursor: 'pointer',
                padding: '0.5rem 1rem',
                borderRadius: '8px',
                backgroundColor:
                  selectedOption === option ? '#e6f0ff' : 'transparent',
                color: selectedOption === option ? '#0066cc' : '#333',
                fontWeight: selectedOption === option ? '600' : '500'
              }}
              onClick={() => setSelectedOption(option)}
            >
              {option}
            </motion.div>
          ))}
        </div>
      </nav>
    );
  }

  // A single card in the listing
  function Section({ image, title, quote, content, tagline, date, slug, isBlogPost }) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        onClick={() => isBlogPost && handleArticleClick(slug)}
        style={{
          cursor: isBlogPost ? 'pointer' : 'default',
          width: '100%',
          maxWidth: isDesktop ? '1000px' : '100%',
          margin: isDesktop ? '0 auto 3rem' : '0 0 2rem 0',
          backgroundColor: isDesktop ? '#fff' : 'transparent',
          borderRadius: isDesktop ? '15px' : '0',
          boxShadow: isDesktop ? '0 4px 24px rgba(0, 0, 0, 0.1)' : 'none',
          padding: isDesktop ? '2rem' : '0'
        }}
      >
        <div style={{ width: '100%' }}>
          <img
            src={image}
            alt={title}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              borderRadius: isDesktop ? '12px' : '0',
              marginBottom: isDesktop ? '2rem' : '1rem'
            }}
          />
        </div>

        <div
          style={{
            width: '100%',
            maxWidth: isDesktop ? '800px' : '100%',
            margin: '0 auto',
            padding: isDesktop ? '0 1rem' : '0'
          }}
        >
          <h2
            style={{
              fontSize: isDesktop ? '2.8rem' : '2rem',
              fontWeight: 'bold',
              marginBottom: '1.5rem',
              textAlign: 'center',
              lineHeight: '1.2',
              color: '#1a1a1a',
              letterSpacing: '-0.02em'
            }}
          >
            {title}
          </h2>

          {tagline && date && (
            <div
              style={{
                textAlign: 'center',
                color: '#666',
                marginBottom: '1.5rem',
                fontSize: isDesktop ? '1.1rem' : '1rem'
              }}
            >
              <span style={{ fontWeight: '600', color: '#0066cc' }}>
                {tagline}
              </span>
              {' '}• {date}
            </div>
          )}

          <blockquote
            style={{
              fontStyle: 'italic',
              marginBottom: '2rem',
              color: '#444',
              textAlign: 'center',
              fontSize: isDesktop ? '1.4rem' : '1.2rem',
              lineHeight: '1.6',
              padding: isDesktop ? '1rem' : '0',
              borderLeft: isDesktop ? '2px solid #0066cc' : 'none',
              backgroundColor: isDesktop ? '#f8f9fa' : 'transparent'
            }}
          >
            {quote}
          </blockquote>

          {content && (
            <p
              style={{
                fontSize: isDesktop ? '1.2rem' : '1.4rem',
                lineHeight: isDesktop ? '1.9' : '2.1',
                color: '#374151',
                marginBottom: '2rem'
              }}
            >
              {content}
            </p>
          )}
        </div>
      </motion.div>
    );
  }

  function ContactSection() {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          backgroundColor: isDesktop ? 'white' : 'transparent',
          padding: isDesktop ? '2rem' : '1rem 0',
          borderRadius: isDesktop ? '16px' : '0',
          boxShadow: isDesktop ? '0 8px 32px rgba(0, 0, 0, 0.08)' : 'none',
          marginTop: '2rem',
          textAlign: 'center',
          maxWidth: isDesktop ? '800px' : '100%',
          margin: isDesktop ? '2rem auto' : '1rem auto'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.5rem'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.75rem'
            }}
          >
            <Mail size={28} style={{ color: '#0066cc' }} />
            <h3
              style={{
                fontSize: isDesktop ? '1.2rem' : '1.4rem',
                fontWeight: '700',
                margin: 0,
                color: '#333'
              }}
            >
              Get in Touch!
            </h3>
          </div>

          <motion.a
            href="mailto:contact@satoshiadvance.com"
            whileHover={{ scale: 1.05, backgroundColor: '#005bb7' }}
            whileTap={{ scale: 0.95 }}
            style={{
              display: 'inline-block',
              backgroundColor: '#0066cc',
              color: 'white',
              padding: isDesktop ? '1rem 1.5rem' : '0.75rem 1.25rem',
              borderRadius: '12px',
              fontSize: isDesktop ? '1rem' : '1.2rem',
              fontWeight: '600',
              textDecoration: 'none',
              boxShadow: '0 4px 12px rgba(0, 102, 204, 0.2)'
            }}
          >
            contact@satoshiadvance.com
          </motion.a>
        </div>
      </motion.div>
    );
  }

  function HoverImage({ media, alt }) {
    return (
      <img
        src={media}
        alt={alt}
        style={{
          width: isDesktop ? '35%' : '100%',
          maxWidth: isDesktop ? '1900px' : '800px',
          borderRadius: '10px',
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
          margin: '0 auto'
        }}
      />
    );
  }

  // Show the items for whichever tab is selected
  const getTabContent = () => {
    if (selectedOption === 'News & Blogs') {
      // Return the filtered list if we clicked an article
      return filteredNews;
    }
    return sections[selectedOption];
  };

  return (
    <div
      style={{
        backgroundColor: isDesktop ? '#f5f5f5' : '#fff',
        minHeight: '100vh',
        padding: isDesktop ? '2rem' : '1rem',
        overflowX: 'hidden',
      }}
    >
      <HeaderBar />

      {/* 1) If a user clicked an article, show the full BlogPost at the top */}
      {currentArticle && (
        <BlogPost post={currentArticle} isDesktop={isDesktop} />
      )}

      {/* 2) Then show the rest of the tab items (minus the clicked one) */}
      {getTabContent().map((item, index) => (
        <Section
          key={index}
          isDesktop={isDesktop}
          handleArticleClick={handleArticleClick}
          {...item}
        />
      ))}

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{ marginTop: '2rem', textAlign: 'center' }}
      >
        <HoverImage
          media={dynamicImage[selectedOption]}
          alt={`Section: ${selectedOption}`}
        />
      </motion.div>

      <ContactSection />
    </div>
  );
}

export default InfoCenter;
