// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import PriceDisplay from './components/PriceDisplay';
import AboutPage from './components/AboutPage';
import SetPasswordPage from './components/SetPasswordPage';
import VerifyPage from './components/VerifyPage';
import InfoCenter from './components/InfoCenter';

const App = () => {
  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId={"783528057692-hb9or18v6otmogkttc9hc1nre886tjvg.apps.googleusercontent.com"}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PriceDisplay />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/verify" element={<VerifyPage />} />
            <Route path="/set-password" element={<SetPasswordPage />} />
            {/* Primary route for InfoCenter */}
            <Route path="/info-center" element={<InfoCenter />} />

            {/* Same InfoCenter, but with an article slug */}
            <Route path="/info-center/:articleSlug" element={<InfoCenter />} />
            <Route path="*" element={<PriceDisplay />} />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
};

export default App;
