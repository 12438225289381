import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
console.log('Is Development Environment:', isDevelopment);

const api = axios.create({
  baseURL: isDevelopment
    ? 'http://localhost:5000'
    : 'https://www.satoshiadvance.com',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true,
  validateStatus: function (status) {
    return status >= 200 && status < 500; // Handle all responses
  }
});

console.log('API Base URL:', api.defaults.baseURL);

const VerifyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState({ loading: true, error: null });

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      console.log('Attempting verification with token:', token);

      if (!token) {
        console.error('No token found in URL');
        setStatus({ loading: false, error: 'Verification token is missing.' });
        return;
      }

      try {
        console.log('Making verification request to:', `${api.defaults.baseURL}/api/verify`);
        
        const response = await api.get('/api/verify', {
          params: { token }
        });
      
        console.log('Verification response:', {
          status: response.status,
          data: response.data,
          headers: response.headers
        });
      
        if (response.status === 200 && response.data?.email) {
          const hasApplication = response.data.hasApplication;
          const redirectUrl = `/set-password?email=${encodeURIComponent(response.data.email)}&hasApplication=${hasApplication}`;
          console.log('Redirecting to:', redirectUrl);
          navigate(redirectUrl);
        } else {
          const errorMessage = response.data?.error || 'Verification failed';
          console.error('Verification failed:', {
            status: response.status,
            error: errorMessage,
            data: response.data
          });
          setStatus({ loading: false, error: errorMessage });
        }
      } catch (error) {
        console.error('Verification error:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        setStatus({ 
          loading: false, 
          error: isDevelopment 
            ? `Error: ${error.message}`
            : 'Unable to connect to verification service. Please try again later.'
        });
      }
    };

    verifyEmail();
  }, [location, navigate]);

  if (status.loading) {
    return (
      <div style={{ 
        textAlign: 'center', 
        marginTop: '20%',
        fontFamily: "'Press Start 2P', cursive",
        color: '#FFFFFF'
      }}>
        <p>Verifying your email...</p>
      </div>
    );
  }

  if (status.error) {
    return (
      <div style={{ 
        textAlign: 'center', 
        marginTop: '20%',
        fontFamily: "'Press Start 2P', cursive",
        color: '#ff4444'
      }}>
        <div>{status.error}</div>
        {isDevelopment && (
          <div style={{ fontSize: '0.8em', marginTop: '10px', opacity: 0.8 }}>
            Environment: Development
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default VerifyPage;

