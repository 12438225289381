import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { ArrowRight, Gamepad2 } from 'lucide-react';

const MEDIA_BASE_URL = "https://media.satoshiadvance.com/images";

// Title Styling
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '2.4rem',
  marginTop: '40px',
  marginBottom: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '1.2rem',
  },
}));

// Red Divider Styling
const RedDividerBar = styled(motion('div'))(({ theme }) => ({
  width: '80%',
  height: '3.5px',
  backgroundColor: '#FF3D3D',
  margin: '30px auto 30px',
  transformOrigin: 'center',
  position: 'relative',
  zIndex: 1,
  boxShadow: `
    0 0 5px #FF3D3D,
    0 0 10px #FF3D3D,
    0 0 20px #FF3D3D,
    0 0 30px #FF0000,
    0 0 50px #FF0000
  `,
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

// Game Card Container
const GameCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(41, 71, 179, 0.3) 0%, rgba(30, 49, 120, 0.2) 100%)',
  border: '2px solid rgba(255, 255, 255, 0.4)',
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '15px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    borderColor: 'rgba(255, 255, 255, 0.8)',
    backgroundColor: 'rgba(41, 71, 179, 0.4)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  }
}));

const GameImage = styled('img')(({ theme }) => ({
  width: '180px',
  height: '180px',
  objectFit: 'cover',
  borderRadius: '8px',
  border: '2px solid rgba(255, 255, 255, 0.4)',
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  [theme.breakpoints.down('sm')]: {
    width: '120px',
    height: '120px',
  }
}));

const IconWrapper = styled(motion.div)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  border: '2px solid rgba(255, 255, 255, 0.4)',
  flexShrink: 0,
});

// Add the missing GameTitle component
const GameTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 'bold',
  color: '#FFFFFF',
  marginBottom: '8px',
  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
  }
}));

const GameDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  color: 'rgba(255, 255, 255, 0.9)',
  marginBottom: '16px',
  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  }
}));

// Add the missing EnterGameText component
const EnterGameText = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  fontSize: '1.2rem',
  color: '#FFFFFF', // White base color
  cursor: 'pointer',
  textDecoration: 'none',
  textShadow: `
    0 0 7px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.5),
    0 0 21px rgba(255, 255, 255, 0.3)
  `,
  transition: 'all 0.3s ease',
  width: '100%',
  '&:hover': {
    color: '#FF3D3D', // Matching the divider bar red
    textShadow: `
      0 0 5px #FF3D3D,
      0 0 10px #FF3D3D,
      0 0 20px #FF3D3D,
      0 0 30px #FF0000,
      0 0 50px #FF0000
    `,
    transform: 'translateY(-2px)',
    textDecoration: 'none',
  }
}));

const PlayCoinGames = () => {
  const games = [
    {
      id: 1,
      image: `${MEDIA_BASE_URL}/PlayImage1.png`,
      title: "Bit Flip",
      description: "Test your luck with the classic coin flip game, Bitcoin style!",
      url: "flip.satoshiadvance.com"
    },
    {
      id: 2,
      image: `${MEDIA_BASE_URL}/PlayImage2.png`,
      title: "Bit Wheel",
      description: "Spin the wheel of fortune and multiply your Satoshis!",
      url: "wheel.satoshiadvance.com"
    },
    {
      id: 3,
      image: `${MEDIA_BASE_URL}/PlayImage3.png`,
      title: "Bit Tanks",
      description: "Command your tank army and battle for Bitcoin supremacy!",
      url: "tanks.satoshiadvance.com"
    }
  ];

  const cardVariants = {
    initial: {
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    animate: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    hover: {
      scale: 1.02,
      transition: {
        duration: 0.2
      }
    }
  };

  const iconVariants = {
    initial: { rotate: 0 },
    hover: {
      rotate: 360,
      transition: {
        duration: 2,
        ease: "linear",
      }
    }
  };

  return (
    <div>
      <SectionTitle>
        Play Coin Games
      </SectionTitle>
      
      <RedDividerBar
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{
          duration: 2,
          ease: "easeInOut",
        }}
      />
      
      <Box sx={{ 
        maxWidth: '1200px',
        margin: '0 auto',
        padding: { xs: 2, md: 4 },
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}>
        {games.map((game, index) => (
          <GameCard
            key={game.id}
            variants={cardVariants}
            initial="initial"
            animate="animate"
            whileHover="hover"
          >
            <IconWrapper
              variants={iconVariants}
              initial="initial"
              whileHover="hover"
            >
              <Gamepad2 size={24} color="rgba(255, 255, 255, 0.9)" />
            </IconWrapper>

            <GameImage 
              src={game.image} 
              alt={game.title}
              onError={(e) => {
                e.target.alt = 'Game image not available';
              }}
            />
            
            <Box sx={{ flex: 1 }}>
              <GameTitle>{game.title}</GameTitle>
              <GameDescription>
                {game.description}
              </GameDescription>
              <EnterGameText
                href={`https://${game.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Enter Game
                <ArrowRight size={20} />
              </EnterGameText>
            </Box>

            <IconWrapper
              variants={iconVariants}
              initial="initial"
              whileHover="hover"
            >
              <Gamepad2 size={24} color="rgba(255, 255, 255, 0.9)" />
            </IconWrapper>
          </GameCard>
        ))}
      </Box>
    </div>
  );
};

export default PlayCoinGames;