import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';

const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

const api = axios.create({
  baseURL: isDevelopment
    ? 'http://localhost:5000'
    : 'https://www.satoshiadvance.com',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true,
  validateStatus: function (status) {
    return status >= 200 && status < 500;
  }
});

// Add this line to debug
console.log('Current API baseURL:', api.defaults.baseURL);

const RetroContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: '#283593',
  padding: '20px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '90%',
  maxWidth: '400px',
  boxShadow: `
    0 0 10px #FFFFFF,
    0 0 20px #00BFFF,
    0 0 30px #00BFFF`,
  outline: 'none',
}));

const RetroTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1.8rem',
  textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`,
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    fontSize: '1.4rem',
  },
}));

const RetroTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.9rem',
    color: '#FFFFFF',
    backgroundColor: '#283593',
    width: '100%',
    margin: '10px 0',
    border: '2px solid #FFFFFF',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#3949ab',
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused': {
      borderColor: '#FFFFFF',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    padding: '15px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
}));

const RetroButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1rem',
  color: '#FFFFFF',
  backgroundColor: '#283593',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '10px 20px',
  margin: '20px 0',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#1a237e',
    borderColor: '#FFFFFF',
  },
}));

const ErrorText = styled(Typography)({
  color: '#ff4444',
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '0.8rem',
  margin: '10px 0',
});

const SetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const hasApplication = params.get('hasApplication') === 'true';

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!email) {
      setError('Invalid or missing email.');
    }
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }
  
    setError('');
    setLoading(true);
  
    try {
      console.log('Attempting to set password for email:', email);
      
      const response = await api.post('/api/set-password', { 
        email, 
        password 
      });
      
      console.log('Set password response:', response);
  
      if (response.status === 200) {
        setSuccess(true);
        const userData = { email, isVerified: true };
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('showWelcomeNotification', 'true');
  
        if (hasApplication) {
          localStorage.setItem('showApplicationNotification', 'true');
          setTimeout(() => navigate('/'), 2000);
        } else {
          setTimeout(() => navigate('/'), 2000);
        }
      } else {
        throw new Error(response.data?.error || 'Failed to set password');
      }
    } catch (error) {
      console.error('Error setting password:', error);
      console.error('Error response:', error.response);
      console.error('Error data:', error.response?.data);
      setError(error.response?.data?.error || error.message || 'Failed to set password. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh" 
      bgcolor="#121212"
    >
      <RetroContainer>
        {success ? (
          <Typography 
            sx={{ 
              color: '#FFFFFF', 
              fontFamily: "'Press Start 2P', cursive", 
              fontSize: '1rem' 
            }}
          >
            {hasApplication 
              ? 'Application submitted successfully! Redirecting...'
              : 'Password set successfully! Redirecting to homepage...'}
          </Typography>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <RetroTitle>Create Your Password</RetroTitle>
            {error && <ErrorText>{error}</ErrorText>}
            <RetroTextField
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              fullWidth
            />
            <RetroTextField
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
              fullWidth
            />
            <RetroButton 
              type="submit" 
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: '#FFFFFF' }} />
              ) : (
                'Set Password'
              )}
            </RetroButton>
          </Box>
        )}
      </RetroContainer>
    </Box>
  );
};

export default SetPasswordPage;

