import React, { useState, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { motion, useInView } from 'framer-motion';

const AnimatedGlowBox = styled(motion(Box))(({ theme }) => ({
  maxWidth: '600px',
  padding: '30px',
  margin: '20px auto',
  backgroundColor: '#283593',
  borderRadius: '10px',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    maxWidth: '90%',
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '2rem',
  marginBottom: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '1rem',
  },
}));

const RetroInput = styled('input')(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1.2rem',
  color: '#FFFFFF',
  backgroundColor: '#1a237e',
  border: '4px solid #FFFFFF',
  borderRadius: '4px',
  padding: '10px',
  width: '80%',
  maxWidth: '400px',
  textAlign: 'center',
  outline: 'none',
  marginBottom: '20px',
  '&:focus': {
    boxShadow: '0 0 20px #9932CC',
  },
  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
    padding: '8px',
  },
}));

// Move this up with your other styled components at the top of the file
const SectionTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "'Press Start 2P', cursive",
    color: '#FFFFFF',
    fontSize: '2.4rem',
    marginTop: '40px',
    marginBottom: '20px',
    textShadow: `
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000
    `,
    '@media (max-width: 600px)': {
      fontSize: '1.2rem',
    },
  }));

const RetroButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1rem',
  color: '#FFFFFF',
  backgroundColor: '#1a237e',
  border: '4px solid #FFFFFF',
  borderRadius: '4px',
  padding: '10px 20px',
  margin: '10px',
  cursor: 'pointer',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#283593',
    boxShadow: '0 0 20px #9932CC',
  },
  '@media (max-width: 600px)': {
    fontSize: '0.7rem',
    padding: '8px 16px',
  },
}));

const ResultText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '1.4rem',
  marginTop: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
  },
}));

const YellowDividerBar = styled(motion('div'))(({ theme }) => ({
    width: '80%',
    height: '4.4px',
    backgroundColor: '#FFF59D', // Light yellow color
    margin: '30px auto 30px',
    transformOrigin: 'center',
    position: 'relative',
    zIndex: 1,
    boxShadow: `
      0 0 5px #FFF59D,
      0 0 10px #FFF59D,
      0 0 20px #FFF59D,
      0 0 30px #FFEB3B,
      0 0 50px #FFEB3B
    `,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  }));
  
  const YellowMotionDivider = () => {
    return (
      <YellowDividerBar
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{
          duration: 2,
          ease: "easeInOut",
        }}
      />
    );
  };

const UsdToBtcConverter = () => {
  const [usdAmount, setUsdAmount] = useState('');
  const [btcResult, setBtcResult] = useState(null);
  const [satoshiResult, setSatoshiResult] = useState(null);
  const boxRef = React.useRef(null);
  const convertAudioRef = useRef(null);
  const isInView = useInView(boxRef, { once: true, margin: '-100px' });

  const playConvertSound = () => {
    if (convertAudioRef.current) {
      convertAudioRef.current.currentTime = 0;
      convertAudioRef.current.play().catch(error => console.log('Error playing convert sound:', error));
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    if (value === '' || (Number(value) <= 1000000000000 && !isNaN(value))) {
      setUsdAmount(value);
    }
  };

  const convertToBTC = async () => {
    playConvertSound();
    try {
      const response = await fetch('/api/btc-price');
      const data = await response.json();
      const btcPrice = data.price;
      const btcValue = (Number(usdAmount) / btcPrice).toFixed(8);
      setBtcResult(btcValue);
      setSatoshiResult(null);
    } catch (error) {
      console.error('Error converting to BTC:', error);
    }
  };
  

  const convertToSatoshi = async () => {
    playConvertSound();
    try {
      const response = await fetch('/api/btc-price');
      const data = await response.json();
      const btcPrice = data.price;
      const btcValue = Number(usdAmount) / btcPrice;
      const satoshiValue = Math.round(btcValue * 100000000).toLocaleString();
      setSatoshiResult(satoshiValue);
      setBtcResult(null);
    } catch (error) {
      console.error('Error converting to Satoshi:', error);
    }
  };


  return (
    <>
      <AnimatedGlowBox
        ref={boxRef}
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1, boxShadow: '0 0 20px #9932CC, 0 0 30px #9932CC, 0 0 40px #9932CC' } : {}}
        whileHover={{
          boxShadow: '0 0 40px #BA55D3, 0 0 60px #BA55D3, 0 0 80px #BA55D3',
        }}
        transition={{
          duration: 1.5,
          ease: 'easeInOut',
        }}
      >
        <audio ref={convertAudioRef} src="/RetroConvert2.mp3" />
        <TitleText>USD to BTC/SATS Converter Tool</TitleText>
        <Box>
        <RetroInput
  type="number"           // Changed from "text" to "number"
  inputMode="decimal"     // Shows decimal keyboard on mobile
  pattern="[0-9]*"       // Ensures only numbers are allowed
  value={usdAmount}
  onChange={handleInputChange}
  placeholder="Enter USD amount"
/>
        </Box>
        <Box>
          <RetroButton onClick={convertToBTC}>
            Convert to BTC
          </RetroButton>
          <RetroButton onClick={convertToSatoshi}>
            Convert to Sats
          </RetroButton>
        </Box>
        {btcResult && (
          <ResultText>
            {btcResult} BTC
          </ResultText>
        )}
        {satoshiResult && (
          <ResultText>
            {satoshiResult} Sats
          </ResultText>
        )}
      </AnimatedGlowBox>
      <SectionTitle>
      Education & Resources
      </SectionTitle>
      <YellowMotionDivider />
    </>
  );
};

export default UsdToBtcConverter;