// src/components/BlogPost.js
import React from 'react';
import { motion } from 'framer-motion';
import AdBanner from './AdBanner';

const BlogPost = ({ post, isDesktop }) => {
  const {
    title,
    date,
    category,
    mainQuote,
    headerImage,
    authors,
    content,
    keyTakeaways,
    affiliateLink
  } = post;

  const renderContent = (section, idx) => {
    // Handle ad banners
    if (section.type === 'ad') {
      return <AdBanner key={`ad-${idx}`} adSlot="4250251137" />;
    }

    // Handle bullet points sections
    if (section.type === 'bullet-points') {
      return (
        <div
          key={idx}
          style={{
            margin: isDesktop ? '3rem auto' : '1rem auto',
            maxWidth: '750px',
            backgroundColor: isDesktop ? '#f8f9fa' : 'transparent',
            padding: isDesktop ? '2rem' : '0',
            borderRadius: isDesktop ? '12px' : '0',
            boxShadow: isDesktop ? '0 2px 8px rgba(0, 0, 0, 0.05)' : 'none'
          }}
        >
          <h3
            style={{
              fontSize: isDesktop ? '1.8rem' : '1.4rem',
              fontWeight: 'bold',
              marginBottom: '1.5rem',
              color: '#1a1a1a'
            }}
          >
            {section.heading}
          </h3>
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0
            }}
          >
            {section.points.map((point, i) => (
              <li
                key={i}
                style={{
                  fontSize: isDesktop ? '1.2rem' : '1.4rem',
                  lineHeight: isDesktop ? '1.9' : '2.1',
                  marginBottom: '1.2rem',
                  paddingLeft: isDesktop ? '1.5rem' : '0.5rem',
                  position: 'relative',
                  color: '#374151'
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    left: 0,
                    color: '#3B82F6',
                    fontWeight: 'bold'
                  }}
                >
                  •
                </span>
                {point}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    // Handle regular sections with or without headings
    return (
      <div
        key={idx}
        style={{
          margin: '2rem auto',
          maxWidth: '800px',
          backgroundColor: isDesktop ? '#fff' : 'transparent',
          padding: isDesktop ? '1.5rem' : '0',
          borderRadius: isDesktop ? '12px' : '0',
          boxShadow: isDesktop ? '0 2px 8px rgba(0, 0, 0, 0.05)' : 'none'
        }}
      >
        {section.heading && (
          <h3
            style={{
              fontSize: isDesktop ? '2rem' : '1.4rem',
              fontWeight: 'bold',
              marginBottom: '1rem',
              color: '#1a1a1a'
            }}
          >
            {section.heading}
          </h3>
        )}
        <p
          style={{
            fontSize: isDesktop ? '1.2rem' : '1.4rem',
            lineHeight: isDesktop ? '1.9' : '2.1',
            margin: 0,
            color: '#374151',
            overflowWrap: 'break-word',
            wordBreak: 'break-word'
          }}
        >
          {section.text}
          {section.links?.map((link, i) => (
            <a
              key={i}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#0066cc',
                textDecoration: 'none',
                marginLeft: '0.5rem'
              }}
            >
              {link.text}
            </a>
          ))}
        </p>
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      style={{
        width: '100%',
        maxWidth: isDesktop ? '1000px' : '100%',
        margin: isDesktop ? '0 auto 3rem' : '0 0 2rem 0',
        backgroundColor: isDesktop ? '#fff' : 'transparent',
        borderRadius: isDesktop ? '15px' : '0',
        boxShadow: isDesktop ? '0 4px 24px rgba(0, 0, 0, 0.1)' : 'none',
        padding: isDesktop ? '2rem' : '0',
      }}
    >
      {/* Header Image */}
      <div style={{ width: '100%' }}>
        <img
          src={headerImage}
          alt={title}
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
            borderRadius: isDesktop ? '12px' : '0',
            marginBottom: isDesktop ? '2rem' : '1rem'
          }}
        />
      </div>

      {/* Article Header */}
      <div style={{
        width: '100%',
        maxWidth: isDesktop ? '800px' : '100%',
        margin: '0 auto',
        padding: isDesktop ? '0 1rem' : '0'
      }}>
        <h2 style={{
          fontSize: isDesktop ? '2.8rem' : '2rem',
          fontWeight: 'bold',
          marginBottom: '1.5rem',
          textAlign: 'center',
          lineHeight: '1.2',
          color: '#1a1a1a',
          letterSpacing: '-0.02em'
        }}>
          {title}
        </h2>

        <div style={{
          textAlign: 'center',
          color: '#666',
          marginBottom: '1.5rem',
          fontSize: isDesktop ? '1.1rem' : '1rem'
        }}>
          <span style={{
            fontWeight: '600',
            color: '#0066cc'
          }}>
            {category}
          </span>
          {' '}• {date}
        </div>

        <blockquote style={{
          fontStyle: 'italic',
          marginBottom: '2rem',
          color: '#444',
          textAlign: 'center',
          fontSize: isDesktop ? '1.4rem' : '1.2rem',
          lineHeight: '1.6',
          padding: isDesktop ? '1rem' : '0',
          borderLeft: isDesktop ? '2px solid #0066cc' : 'none',
          backgroundColor: isDesktop ? '#f8f9fa' : 'transparent'
        }}>
          {mainQuote}
        </blockquote>

        <p style={{
          textAlign: 'center',
          fontSize: isDesktop ? '1.1rem' : '1rem',
          color: '#666',
          marginBottom: '2rem'
        }}>
          {authors}
        </p>

        {/* Key Takeaways */}
        <div style={{
          margin: isDesktop ? '3rem auto' : '1rem auto',
          maxWidth: '750px',
          backgroundColor: isDesktop ? '#f8f9fa' : 'transparent',
          padding: isDesktop ? '2rem' : '0',
          borderRadius: isDesktop ? '12px' : '0',
          boxShadow: isDesktop ? '0 2px 8px rgba(0, 0, 0, 0.05)' : 'none'
        }}>
          <h3 style={{
            fontSize: isDesktop ? '1.8rem' : '1.4rem',
            fontWeight: 'bold',
            marginBottom: '1.5rem',
            color: '#1a1a1a'
          }}>
            Key Takeaways:
          </h3>
          <ul style={{
            listStyle: 'none',
            padding: 0,
            margin: 0
          }}>
            {keyTakeaways.map((point, i) => (
              <li key={i} style={{
                fontSize: isDesktop ? '1.2rem' : '1.4rem',
                lineHeight: isDesktop ? '1.9' : '2.1',
                marginBottom: '1.2rem',
                paddingLeft: isDesktop ? '1.5rem' : '0.5rem',
                position: 'relative',
                color: '#374151'
              }}>
                <span style={{
                  position: 'absolute',
                  left: 0,
                  color: '#3B82F6',
                  fontWeight: 'bold'
                }}>•</span>
                {point}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        {content.map((section, idx) => renderContent(section, idx))}

        {/* Affiliate Link */}
        {affiliateLink && (
          <div style={{
            textAlign: 'center',
            margin: '2rem auto'
          }}>
            <a
              href={affiliateLink.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'inline-block',
                padding: '1rem 2rem',
                backgroundColor: '#0066cc',
                color: 'white',
                borderRadius: '8px',
                textDecoration: 'none',
                fontWeight: '600',
                boxShadow: '0 4px 12px rgba(0, 102, 204, 0.2)'
              }}
            >
              {affiliateLink.text}
            </a>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default BlogPost;