import React, { useEffect } from 'react';

const AdBanner = ({ adSlot }) => {
  useEffect(() => {
    // Initialize adsbygoogle when the component mounts
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.error("AdSense error:", error);
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: '2rem 0' }}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block', textAlign: 'center' }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-1837036543658433"
        data-ad-slot={adSlot}
      ></ins>
    </div>
  );
};

export default AdBanner;
