import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, InputAdornment, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

axios.defaults.baseURL = window.location.hostname === 'localhost'
  ? 'http://localhost:5000' // Development
  : 'https://www.satoshiadvance.com'; // Production

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';


const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  padding: '20px',
  backgroundColor: '#1a237e',
  borderRadius: '10px',
  textAlign: 'center',
  zIndex: 10000,
  boxShadow: '0px 0px 20px 0px #00BFFF',
  maxHeight: '90vh',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  '@media (max-width: 600px)': {
    padding: '15px',
    width: '85%'
  }
}));

const Overlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 9999,
});

const StyledTabs = styled(Tabs)({
  marginBottom: '20px',
  '& .MuiTab-root': {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.8rem',
    opacity: 0.7,
    '&.Mui-selected': {
      color: '#00BFFF',
      opacity: 1,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#00BFFF',
  },
});

const RetroTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1.8rem',
  textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`,
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
  '@media (max-width: 600px)': {
    fontSize: '1.4rem',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#283593',
  borderRadius: '5px',
  marginBottom: '15px',
  input: {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '0.9rem',
    '@media (max-width: 600px)': {
      fontSize: '0.8rem',
    }
  },
}));

const AuthPopup = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [step, setStep] = useState('form');
  const [error, setError] = useState('');
  const [mode, setMode] = useState('login');

  const handleTabChange = (event, newMode) => {
    setMode(newMode);
    setError('');
    setStep('form');  // Changed from 'main' to 'form'
  };


  const handleEmailSubmit = async () => {
    try {
      if (mode === 'login') {
        const response = await axios.post('/api/login', { email, password });

        if (response.status === 200) {
          // Store user data consistently across storage methods
          const userData = {
            email: response.data.email,
            isVerified: response.data.isVerified,
            lastLogin: new Date().toISOString()
          };

          // Store in both localStorage and sessionStorage
          localStorage.setItem('userEmail', response.data.email);
          sessionStorage.setItem('userEmail', response.data.email);
          localStorage.setItem('user', JSON.stringify(userData));
          sessionStorage.setItem('user', JSON.stringify(userData));

          console.log('Auth state stored:', {
            email: response.data.email,
            localStorage: localStorage.getItem('userEmail'),
            sessionStorage: sessionStorage.getItem('userEmail'),
            timestamp: new Date().toISOString()
          });

          // Close popup and refresh
          onClose();
          window.location.reload();
        }
      } else {
        // Signup flow
        if (!email) {
          setError('Please enter an email address');
          return;
        }

        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setError('Please enter a valid email address');
          return;
        }

        const response = await axios.post('/api/signup', { email });

        if (response.status === 201 || response.status === 200) {
          // Store signup email temporarily
          sessionStorage.setItem('pendingSignupEmail', email);
          setStep('checkEmail');
          setError('');
        }
      }
    } catch (error) {
      console.error('Auth error:', {
        mode,
        error: error.message,
        response: error.response?.data
      });

      // Handle specific error cases
      if (error.response?.status === 401) {
        setError('Invalid email or password');
      } else if (error.response?.status === 429) {
        setError('Too many attempts. Please try again later');
      } else {
        setError(
          error.response?.data?.error ||
          `Failed to ${mode === 'login' ? 'login' : 'send verification email'}`
        );
      }
    }
  };



  
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const response = await axios.post('/api/google-auth', {
        token: credentialResponse.credential,
        mode: mode
      });

      if (response.status === 200) {
        // Store user data consistently
        const userData = {
          email: response.data.email,
          isVerified: true
        };

        // Store in both localStorage and sessionStorage
        localStorage.setItem('userEmail', response.data.email);
        sessionStorage.setItem('userEmail', response.data.email);
        localStorage.setItem('user', JSON.stringify(userData));
        sessionStorage.setItem('user', JSON.stringify(userData));

        console.log('Google Auth stored:', {
          email: response.data.email,
          localStorage: localStorage.getItem('userEmail'),
          sessionStorage: sessionStorage.getItem('userEmail')
        });

        // Fix: Change the comparison
        if (mode === 'signup' && response.data.isNewUser === true) {
          localStorage.setItem('showWelcomeNotification', 'true');
        }


        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Google auth error:', error);
      setError(`Failed to ${mode} with Google`);
    }
  };


  const handleGoogleError = () => {
    setError('Google authentication failed');
  };

  return (
    <>
      <Overlay
        onClick={onClose}
        onKeyDown={(e) => e.key === 'Escape' && onClose()}
        tabIndex={0}
      />
      <PopupContainer onClick={(e) => e.stopPropagation()}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            color: '#FFFFFF'
          }}
        >
          <CloseIcon />
        </IconButton>

        <RetroTitle>Welcome</RetroTitle>

        {step === 'form' ? (
          <>
            <StyledTabs value={mode} onChange={handleTabChange} centered>
              <Tab value="login" label="Login" />
              <Tab value="signup" label="Sign Up" />
            </StyledTabs>

            {error && (
              <Typography sx={{
                color: '#ff4444',
                mb: 2,
                fontFamily: "'Press Start 2P', cursive",
                fontSize: '0.8rem'
              }}>
                {error}
              </Typography>
            )}


            <StyledTextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon sx={{ color: '#FFFFFF' }} />
                  </InputAdornment>
                ),
              }}
            />
            {mode === 'login' && (
              <StyledTextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
                fullWidth
              />
            )}
            <Button
              onClick={handleEmailSubmit}
              sx={{
                backgroundColor: '#00BFFF',
                color: '#FFFFFF',
                fontFamily: "'Press Start 2P', cursive",
                textTransform: 'none',
                '&:hover': { backgroundColor: '#0095ff' },
                marginBottom: '15px',
                padding: '10px',
                fontSize: { xs: '0.8rem', sm: '1rem' }
              }}
              fullWidth
            >
              {mode === 'login' ? 'Login' : 'Sign Up'}
            </Button>

            <Box sx={{ width: '100%', mt: 2 }}>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleError}
                type="standard"
                theme="filled_blue"
                size="large"
                text={mode === 'login' ? 'signin_with' : 'continue_with'}
                shape="rectangular"
                width="100%"
              />
            </Box>
          </>
        ) : (
          <Box sx={{ textAlign: 'center', color: '#FFFFFF' }}>
            <RetroTitle>Check Your Email</RetroTitle>
            <Typography sx={{
              mb: 3,
              fontFamily: "'Press Start 2P', cursive",
              fontSize: '0.8rem'
            }}>
              We sent a verification link to {email}
            </Typography>
            <Typography sx={{
              mb: 4,
              fontFamily: "'Press Start 2P', cursive",
              fontSize: '0.7rem',
              opacity: 0.8
            }}>
              Click the link in your email to verify your account and set your password
            </Typography>
            <Button
              onClick={onClose}
              sx={{
                backgroundColor: '#00BFFF',
                color: '#FFFFFF',
                fontFamily: "'Press Start 2P', cursive",
                textTransform: 'none',
                '&:hover': { backgroundColor: '#0095ff' },
                padding: '10px',
                fontSize: { xs: '0.8rem', sm: '1rem' }
              }}
              fullWidth
            >
              Close
            </Button>
          </Box>
        )}
      </PopupContainer>
    </>
  );
}; // Add this closing curly brace for the AuthPopup component

export default AuthPopup; // Move this to be after the component closing