import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import styled from "@emotion/styled";
import html2canvas from "html2canvas";
import { Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/system';

const AUDIO_BASE_URL = "https://media.satoshiadvance.com/audio";
const MEDIA_BASE_URL = "https://media.satoshiadvance.com/images";

const SectionTitle = muiStyled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '2.4rem',
  marginTop: '40px',
  marginBottom: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '1.2rem',
  },
}));


export const DividerBar = styled(motion.div)(({ theme }) => ({
  width: "80%", // Default width for larger screens
  height: "5px", // Thickness of the bar
  backgroundColor: "#FFFFFF",
  margin: "20px auto 0",
  marginBottom: "30px",
  transformOrigin: "center", // Ensure it grows from the center point
  // Neon pink glowing effect
  boxShadow: `
    0 0 10px #FFFFFF,
    0 0 20px #FF69B4, /* Neon pink glow */
    0 0 30px #FF69B4,
    0 0 40px #FF69B4,
    0 0 70px #FFFFFF, /* White glow */
    0 0 80px #FFFFFF,
    0 0 100px #FF69B4,
    0 0 150px #FF69B4
  `,
  [theme?.breakpoints?.down("sm")]: {
    width: "95%", // Nearly full width for smaller devices
  },
}));

export const MotionDivider = () => {
  return (
    <DividerBar
      initial={{ scaleX: 0 }} // Start as a point in the middle
      animate={{
        scaleX: 1, // Grow to full width
        rotateX: 15, // Slight tilt for a 3D effect
      }}
      transition={{
        duration: 2, // Grow over 2 seconds
        ease: "easeInOut", // Smooth animation
      }}
    />
  );
};

const DOG_TUNES = [
  "DogTunes7.wav",
  "DogTunes5.wav",
  "DogTunes3.wav",
  "DogTunes4.wav",
  "DogTunes2.wav",
  "DogTunes6.wav",
  "DogTunes1.wav",
  "DogTunes8.wav",
  "DogTunes9.wav",
  "DogTunes10.wav",
  "DogTunes11.mp3"
];



function DogSprite() {
  const [showLargeImage, setShowLargeImage] = useState(false);
  const audioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dog-barking.wav`));
  const backAudioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dogs-bark-english-sheep.wav`));
  const trickAudioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dogs-bark-golden-retriever.wav`));
  const [currentBreed, setCurrentBreed] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [isSpinning, setIsSpinning] = useState({ download: false, play: false, action: false });
  const [previousBreeds, setPreviousBreeds] = useState([]);
  const [nextBreed, setNextBreed] = useState("");
  const [nextDescription, setNextDescription] = useState("");
  const [nextImageUrl, setNextImageUrl] = useState("");
  const [nextActionImage, setNextActionImage] = useState({ action: "", imageUrl: "" });
  const [loading, setLoading] = useState(true); // Add a loading state
  const downloadAudioRef = useRef(new Audio(`${AUDIO_BASE_URL}/dogs-bark-jack-russell.wav`));
  const [attemptsLeft, setAttemptsLeft] = useState(3); // Number of attempts remaining
  const [result, setResult] = useState(null); // Game result: "Winner!" or "Game Over"
  const [clickTimeout, setClickTimeout] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [gameStarted, setGameStarted] = useState(false);
  const [dogTunesPaths] = useState(
    DOG_TUNES.map(file => `${AUDIO_BASE_URL}/${file}`)
  );
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState("low");
  const musicAudioRef = useRef(new Audio(dogTunesPaths[0]));



  const hotspot = {
    x1: 5925, // Left boundary
    y1: 5050, // Top boundary
    x2: 6550, // Right boundary
    y2: 5550, // Bottom boundary
  };

  const cardRef = useRef(null);

  const ACTIONS = [
    "Sit",
    "Stand",
    "Lie Down",
    "Bark",
    "Jump",
    "Run",
    "Catch Frisbee",
    "Shake Hands",
    "Roll Over",
    "Bow (Play Position)",
  ];



  const fetchDogDescription = async (breedName) => {
    try {
      const response = await fetch(`${window.location.origin}/api/openai/generate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          prompt: `Provide a brief 2-3 line description of the ${breedName} dog breed, including a bit about its history and characteristics. Keep it concise and engaging.`,
          type: "text",
        }),
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();
      return data.result || "Unable to load description.";
    } catch (error) {
      console.error("Error fetching dog description:", error);
      return "Unable to load description.";
    }
  };



  const fetchDogImage = async (breedName, action = null) => {
    try {
      const actionPrompt = action ? `, performing the action: "${action}"` : "";
      const response = await fetch(`${window.location.origin}/api/openai/generate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          prompt: `A retro 32-bit pixel art sprite of a ${breedName} dog breed${actionPrompt}. The sprite should be well-defined with bold and thick black outlines around the dog, ensuring no part is cut off. Use a deep blue background color (#1a237e) that matches the website. Keep the sprite centered with padding to prevent cropping.`,
          type: "image",
        }),
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();

      if (!data.image_url) {
        throw new Error("No image URL in response");
      }

      return data.image_url
        ? `${window.location.origin}/proxy-image?url=${encodeURIComponent(data.image_url)}`
        : `${MEDIA_BASE_URL}/placeholder.png`;
    } catch (error) {
      console.error("Error fetching dog image:", error);
      return `${MEDIA_BASE_URL}/placeholder.png`;
    }
  };


  const preloadNextBreed = async () => {
    try {
      const randomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
      const newBreed = DOG_BREEDS[randomIndex];
      const description = await fetchDogDescription(newBreed);
      const imageUrl = await fetchDogImage(newBreed);

      console.log("Preloading next breed:", newBreed);

      // Ensure the nextBreed state is always set
      setNextBreed(newBreed || "Unknown Breed");
      setNextDescription(description || "Unable to load description.");
      setNextImageUrl(imageUrl || "/placeholder.png"); // Fallback to placeholder image if empty
    } catch (error) {
      console.error("Error preloading next breed:", error);
      setNextBreed("Error");
      setNextDescription("Unable to load description.");
      setNextImageUrl("/placeholder.png");
    }
  };

  const preloadNextAction = async () => {
    if (!currentBreed) return false;

    try {
      const randomAction = ACTIONS[Math.floor(Math.random() * ACTIONS.length)];
      const imageUrl = await fetchDogImage(currentBreed, randomAction);

      // Preload the image before updating state
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = imageUrl;
      });

      setNextActionImage({ action: randomAction, imageUrl });
      return true;
    } catch (error) {
      console.error('Error preloading next action:', error);
      setNextActionImage({ action: "", imageUrl: "/placeholder.png" });
      return false;
    }
  };

  const showNextBreed = () => {
    if (!nextBreed || !nextImageUrl) {
      console.error("Next breed or image is not ready yet.");
      return;
    }

    setLoading(true);
    playBarkSound();

    // Store current breed in history before switching
    if (currentBreed) {
      setPreviousBreeds(prev => [
        ...prev,
        {
          breed: currentBreed,
          description: currentDescription,
          imageUrl: currentImageUrl
        }
      ]);
    }

    setTimeout(() => {
      // Store current preloaded data
      const preloadedBreed = nextBreed;
      const preloadedDesc = nextDescription;
      const preloadedImg = nextImageUrl;

      // Clear next breed data
      setNextBreed("");
      setNextDescription("");
      setNextImageUrl("");

      // Clear next action data
      setNextActionImage({ action: "", imageUrl: "" });

      // Update current with preloaded data
      setCurrentBreed(preloadedBreed);
      setCurrentDescription(preloadedDesc);
      setCurrentImageUrl(preloadedImg);

      // Start preloading next breed
      const preloadNext = async () => {
        try {
          const randomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
          const newBreed = DOG_BREEDS[randomIndex];

          const [nextDesc, nextImg] = await Promise.all([
            fetchDogDescription(newBreed),
            fetchDogImage(newBreed)
          ]);

          setNextBreed(newBreed);
          setNextDescription(nextDesc);
          setNextImageUrl(nextImg);

          // After next breed is loaded, preload an action
          await preloadNextAction();
        } catch (error) {
          console.error("Error preloading next breed:", error);
        } finally {
          setLoading(false);
        }
      };

      // Start preloading process
      preloadNext();
    }, 500);
  };

  const navigateBack = () => {
    if (previousBreeds.length > 0) {
      playBackBarkSound();
      const lastBreed = previousBreeds[previousBreeds.length - 1];
      setPreviousBreeds((prev) => prev.slice(0, -1));
      setCurrentBreed(lastBreed.breed);
      setCurrentDescription(lastBreed.description);
      setCurrentImageUrl(lastBreed.imageUrl);

      // Preload next action for the breed we just navigated back to
      preloadNextAction();
    }
  };

  const generateRandomAction = async () => {
    if (!currentBreed) return;

    setIsSpinning((prev) => ({ ...prev, action: true }));
    playTrickBarkSound();

    try {
      // If we have a preloaded action, use it immediately
      if (nextActionImage.imageUrl && nextActionImage.imageUrl !== "/placeholder.png") {
        setCurrentImageUrl(nextActionImage.imageUrl);
        setNextActionImage({ action: "", imageUrl: "" }); // Clear the next action
        setIsSpinning((prev) => ({ ...prev, action: false })); // Stop spinning immediately

        // Start preloading the next action for future use
        preloadNextAction();
      } else {
        // If no preloaded action, load one immediately
        const randomAction = ACTIONS[Math.floor(Math.random() * ACTIONS.length)];
        const imageUrl = await fetchDogImage(currentBreed, randomAction);

        // Preload the image
        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = reject;
          img.src = imageUrl;
        });

        // Update current image immediately
        setCurrentImageUrl(imageUrl);
        setIsSpinning((prev) => ({ ...prev, action: false })); // Stop spinning after loading

        // Start preloading next action
        preloadNextAction();
      }
    } catch (error) {
      console.error("Error generating random action:", error);
      setCurrentImageUrl("/placeholder.png");
      setIsSpinning((prev) => ({ ...prev, action: false }));
    }
  };

  const handleImageClick = (e) => {
    e.stopPropagation(); // Prevent bubbling up to the parent container

    if (result) return; // Stop if the game is already over

    const rect = e.target.getBoundingClientRect(); // Get the image's dimensions on screen
    const clickX = e.clientX - rect.left; // Calculate X relative to the image
    const clickY = e.clientY - rect.top;  // Calculate Y relative to the image

    console.log(`Click at X: ${clickX}, Y: ${clickY}`); // Debugging: Log click coordinates
    console.log(`Hotspot: X1=${hotspot.x1}, Y1=${hotspot.y1}, X2=${hotspot.x2}, Y2=${hotspot.y2}`);

    // Check if the click is inside the hotspot
    if (clickX >= hotspot.x1 && clickX <= hotspot.x2 && clickY >= hotspot.y1 && clickY <= hotspot.y2) {
      console.log("You clicked inside the hotspot!");
      setResult("Winner!"); // User wins
    } else {
      console.log("You clicked outside the hotspot.");
      // Decrement attempts and check if the user has lost
      setAttemptsLeft((prev) => {
        const newAttempts = prev - 1;
        if (newAttempts <= 0) {
          setResult("Game Over"); // User loses
        }
        return newAttempts;
      });
    }
  };





  const downloadCard = async () => {
    if (cardRef.current) {
      setIsSpinning((prev) => ({ ...prev, download: true }));
      playDownloadBarkSound();

      setTimeout(async () => {
        try {
          const canvas = await html2canvas(cardRef.current, {
            backgroundColor: null,
            useCORS: true,
            allowTaint: true,
            imageTimeout: 0,
            logging: true,
          });

          const dataURL = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = dataURL;
          link.download = `${currentBreed.replace(/\s+/g, "_")}dogcard.png`;
          link.click();
        } catch (error) {
          console.error("Error generating card:", error);
        } finally {
          setIsSpinning((prev) => ({ ...prev, download: false }));
        }
      }, 500);
    }
  };

  const speakDescription = () => {
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance(
        `${currentBreed}: ${currentDescription}`
      );
      utterance.lang = "en-US";
      utterance.rate = 0.9;
      utterance.pitch = 1.0;
      setIsSpinning((prev) => ({ ...prev, play: true })); // Start spin
      setTimeout(() => {
        setIsSpinning((prev) => ({ ...prev, play: false })); // Stop spin
        synth.speak(utterance);
      }, 500); // Spin duration
    } else {
      alert("Speech Synthesis not supported in this browser.");
    }
  };

  useEffect(() => {
    const initializeBreeds = async () => {
      setLoading(true);

      try {
        // 1. Load initial breed data
        const randomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
        const initialBreed = DOG_BREEDS[randomIndex];

        const [description, imageUrl] = await Promise.all([
          fetchDogDescription(initialBreed),
          fetchDogImage(initialBreed)
        ]);

        // 2. Set initial breed data and stop loading
        setCurrentBreed(initialBreed);
        setCurrentDescription(description);
        setCurrentImageUrl(imageUrl);
        setLoading(false);

        // 3. After a short delay, start background preloading
        setTimeout(async () => {
          try {
            // Preload next breed
            const nextRandomIndex = Math.floor(Math.random() * DOG_BREEDS.length);
            const newBreed = DOG_BREEDS[nextRandomIndex];

            const [nextDesc, nextImg] = await Promise.all([
              fetchDogDescription(newBreed),
              fetchDogImage(newBreed)
            ]);

            // Quietly store next breed data
            setNextBreed(newBreed);
            setNextDescription(nextDesc);
            setNextImageUrl(nextImg);

            // After next breed is stored, preload an action for current breed
            await preloadNextAction();
          } catch (error) {
            console.error("Error in background preloading:", error);
          }
        }, 1000); // Delay preloading to ensure first breed is stable

      } catch (error) {
        console.error("Error initializing breeds:", error);
        setLoading(false);
      }
    };

    initializeBreeds();
  }, []);

  useEffect(() => {
    // Load the chosen track
    musicAudioRef.current.src = dogTunesPaths[currentTrackIndex];

    if (isMusicPlaying) {
      // If we were playing, immediately play the new track
      musicAudioRef.current.play().catch(err => {
        console.log("Music autoplay error:", err);
      });
    }
  }, [currentTrackIndex, isMusicPlaying, dogTunesPaths]);

  useEffect(() => {
    const newVolume = (volumeLevel === "low") ? 0.3 : 0.7;
    musicAudioRef.current.volume = newVolume;
  }, [volumeLevel]);

  useEffect(() => {
    return () => {
      musicAudioRef.current.pause();
      musicAudioRef.current.src = "";
    };
  }, []);


  useEffect(() => {
    // Initialize both audio files
    audioRef.current.volume = 0.5;
    backAudioRef.current.volume = 0.5;
    trickAudioRef.current.volume = 0.5;
    downloadAudioRef.current.volume = 0.5;
    audioRef.current.load();
    backAudioRef.current.load();
    trickAudioRef.current.load();
    downloadAudioRef.current.load();
    console.log("Audio files initialized");

    audioRef.current.addEventListener('play', () => console.log('Forward bark playing'));
    backAudioRef.current.addEventListener('play', () => console.log('Back bark playing'));
  }, []);

  useEffect(() => {
    const handleEscape = (e) => {
      console.log('Key pressed:', e.key); // Debug log
      if (e.key === 'Escape' && showLargeImage) {
        console.log('Escape pressed, closing image'); // Debug log
        setShowLargeImage(false);
        setResult(null);
        setAttemptsLeft(3);
      }
    };

    // Add the event listener
    window.addEventListener('keydown', handleEscape);

    // Cleanup
    return () => window.removeEventListener('keydown', handleEscape);
  }, [showLargeImage]); // Add showLargeImage to the dependency array


  const playBarkSound = () => {
    console.log("Attempting to play sound...");
    try {
      audioRef.current.currentTime = 0;
      audioRef.current.play()
        .then(() => console.log("Sound played successfully"))
        .catch(error => console.error("Error playing sound:", error));
    } catch (error) {
      console.error("Error in playBarkSound:", error);
    }
  };

  const playBackBarkSound = () => {
    console.log("Attempting to play back sound...");
    try {
      backAudioRef.current.currentTime = 0;
      backAudioRef.current.play()
        .then(() => console.log("Back sound played successfully"))
        .catch(error => console.error("Error playing back sound:", error));
    } catch (error) {
      console.error("Error in playBackBarkSound:", error);
    }
  };

  const playTrickBarkSound = () => {
    console.log("Attempting to play trick sound...");
    try {
      trickAudioRef.current.currentTime = 0;
      trickAudioRef.current.play()
        .then(() => console.log("Trick sound played successfully"))
        .catch(error => console.error("Error playing trick sound:", error));
    } catch (error) {
      console.error("Error in playTrickBarkSound:", error);
    }
  };

  const playDownloadBarkSound = () => {
    console.log("Attempting to play download sound...");
    try {
      downloadAudioRef.current.currentTime = 0;
      downloadAudioRef.current.play()
        .then(() => console.log("Download sound played successfully"))
        .catch(error => console.error("Error playing download sound:", error));
    } catch (error) {
      console.error("Error in playDownloadBarkSound:", error);
    }
  };


  const handleMusicPlayPause = (e) => {
    e.stopPropagation(); // so we don't trigger other parent onClicks
    if (!isMusicPlaying) {
      // was paused, now we want to play
      musicAudioRef.current.play().then(() => {
        setIsMusicPlaying(true);
      }).catch(err => console.log("Music play error:", err));
    } else {
      // was playing, now pause
      musicAudioRef.current.pause();
      setIsMusicPlaying(false);
    }
  };

  const handleSkipTrack = (e) => {
    e.stopPropagation();
    const nextIndex = (currentTrackIndex + 1) % dogTunesPaths.length;
    setCurrentTrackIndex(nextIndex);
  };

  const handleVolumeToggle = (e) => {
    e.stopPropagation();
    setVolumeLevel(prev => (prev === "low" ? "medium" : "low"));
  };





  return (


    <div>

      <SectionTitle>
        Dog Cards
      </SectionTitle>
      {/* Motion Divider placed above the blue card */}
      <MotionDivider />


      <div
        style={{
          marginTop: "2rem",
          textAlign: "center",
          backgroundColor: "#1a237e",
          padding: "2rem",
          borderRadius: "8px",
          color: "#fff",
          cursor: "pointer",
          transition: "transform 0.2s ease-in-out",
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (clickTimeout) {
            clearTimeout(clickTimeout);
            setClickTimeout(null);
          } else {
            const timeout = setTimeout(() => {
              playBarkSound();
              showNextBreed();
              setClickTimeout(null);
            }, 250);
            setClickTimeout(timeout);
          }
        }}
        onDoubleClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (clickTimeout) {
            clearTimeout(clickTimeout);
            setClickTimeout(null);
          }
          console.log('Double click detected - showing large image');
          setShowLargeImage(true);
          setAttemptsLeft(3);
          setResult(null);
        }}
      >


        {/* The card wrapper */}
        <div
          ref={cardRef}
          style={{
            backgroundColor: "#1a237e",
            padding: "1rem",
            borderRadius: "8px",
          }}
        >
          {loading ? (
            // Show "Loading..." while the app is fetching data
            <p
              style={{
                fontFamily: "'Press Start 2P', cursive",
                fontSize: "1.5rem",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              Loading Breed...
            </p>
          ) : (
            // Display the breed details once loaded
            <div>
              <h2
                style={{
                  fontFamily: "'Press Start 2P', cursive",
                  marginBottom: "1.5rem",
                  fontSize: "1.75rem",
                  textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                }}
              >
                {currentBreed}
              </h2>

              <div style={{ margin: "1rem auto" }}>
                <img
                  src={currentImageUrl}
                  alt={`${currentBreed} sprite`}
                  onError={(e) => {
                    console.error("Error loading image:", e.target.src);
                    e.target.onerror = null; // Prevent infinite error loop
                  }}
                  style={{
                    width: "100%",     // Let it shrink on small screens
                    maxWidth: "256px", // Don’t exceed 256px on large screens
                    height: "auto",    // Keep aspect ratio
                    backgroundColor: "#1a237e",
                    padding: "10px",
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                  }}
                />
              </div>

              <div
                style={{
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "1.1rem",
                  lineHeight: "1.6",
                  margin: "1.5rem auto",
                  maxWidth: "600px",
                  padding: "1rem",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: "4px",
                  minHeight: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ margin: 0, padding: "0.5rem" }}>{currentDescription}</p>
              </div>
            </div>
          )}
        </div>


        {/* Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          {/* Leftward-Facing Button */}
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent propagation of the click event
              navigateBack(); // Call navigateBack when clicked
              playBackBarkSound();
            }}
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              border: "none",
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              fontSize: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
            }}
          >
            ◀ {/* Unicode for left arrow */}
          </button>

          {/* Download Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              downloadCard();
              playDownloadBarkSound();
            }}
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              border: "none",
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              fontSize: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              animation: isSpinning.download ? "spinTwice 0.5s ease-in-out" : "none",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
            }}
          >
            ▼
          </button>

          {/* Play Description Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              speakDescription();
            }}
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              border: "none",
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              fontSize: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              // Removed animation for the play button
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
              outline: "none", // Prevent focus outline
            }}
          >
            ▶
          </button>

          {/* Generate Random Action Button */}
          {/* Generate Random Action Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              generateRandomAction();
              playTrickBarkSound();
            }}
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              border: "none",
              background: "linear-gradient(145deg, #3f51b5, #283593)",
              color: "white",
              fontSize: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2), -4px -4px 10px rgba(255, 255, 255, 0.1)",
              transition: "transform 0.2s ease, box-shadow 0.2s ease",
              outline: "none",
              animation: isSpinning.action ? "spinAndPulse 1.2s infinite linear" : "none", // Spin every 1.2s
            }}
          >
            ▲
          </button>

        </div>


        {/* Show Large Image Section */}
        {showLargeImage && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              overflow: "auto",
              zIndex: 9999,
            }}
            onClick={() => {
              if (!gameStarted) {
                setGameStarted(true); // Start the game
                setLoadingMessage(""); // Clear loading message
              }
            }}
          >
            {/* Instruction Container */}
            {/* Instruction Container */}
            <div
              style={{
                position: "fixed",
                top: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#FFFFFF",
                fontFamily: "'Press Start 2P', cursive",
                fontSize: "14px",
                textAlign: "center",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                padding: "10px 20px",
                borderRadius: "8px",
                zIndex: 10001,
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
                width: "90%",
              }}
            >

              {/* 3 Music Buttons Row */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                  marginBottom: "8px", // a bit of spacing
                }}
              >
                {/* 1) Play / Pause */}
                <button
                  onClick={handleMusicPlayPause}
                  style={{
                    border: "2px solid #fff",
                    borderRadius: "4px",
                    backgroundColor: "#1a237e",
                    color: "#fff",
                    width: "50px",
                    height: "50px",
                    fontFamily: "'Press Start 2P', cursive",
                    cursor: "pointer",
                  }}
                >
                  {isMusicPlaying ? "⏸" : "▶"}
                </button>

                {/* 2) Skip */}
                <button
                  onClick={handleSkipTrack}
                  style={{
                    border: "2px solid #fff",
                    borderRadius: "4px",
                    backgroundColor: "#1a237e",
                    color: "#fff",
                    width: "50px",
                    height: "50px",
                    fontFamily: "'Press Start 2P', cursive",
                    cursor: "pointer",
                  }}
                >
                  ⏭
                </button>

                {/* 3) Volume */}
                <button
                  onClick={handleVolumeToggle}
                  style={{
                    border: "2px solid #fff",
                    borderRadius: "4px",
                    backgroundColor: "#1a237e",
                    color: "#fff",
                    width: "50px",
                    height: "50px",
                    fontFamily: "'Press Start 2P', cursive",
                    cursor: "pointer",
                  }}
                >
                  {volumeLevel === "low" ? "🔈" : "🔉"}
                </button>
              </div>


              <p>Find the Bearded Collie!</p>
              <p>Attempts Left: {attemptsLeft}</p>
              {!gameStarted && (
                <>
                  {navigator.platform.toLowerCase().includes('mac') ? (
                    <>
                      <p>Step 1 - Use two fingers on trackpad to scroll</p>
                      <p>Step 2 - Navigate to locate the dog breed</p>
                      <p>Step 3 - Click on Dog Breed (3 chances to win!)</p>
                    </>
                  ) : (
                    <>
                      <p>Step 1 - Use mouse wheel or middle-click to scroll</p>
                      <p>Step 2 - Navigate to locate the dog breed</p>
                      <p>Step 3 - Click on Dog Breed (3 chances to win!)</p>
                    </>
                  )}
                </>
              )}
            </div>


            {/* Loading/Ready Message */}
            {!gameStarted && (
              <div
                style={{
                  position: "fixed",
                  top: "250px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: loadingMessage === "Ready!" ? "#00FF00" : "#FFD700",
                  fontFamily: "'Press Start 2P', cursive",
                  fontSize: "18px",
                  textAlign: "center",
                  zIndex: 10001,
                }}
              >
                {loadingMessage}
                {loadingMessage === "Ready!" && (
                  <p
                    style={{
                      color: "#FFFFFF",
                      marginTop: "10px",
                      fontSize: "16px",
                    }}
                  >
                    Click Here to Start Mini Game
                  </p>
                )}
              </div>
            )}

            {/* Large Image */}
            <div style={{ position: "relative", marginTop: "350px" }}>
              <img
                src={`${MEDIA_BASE_URL}/FindDogBreed.png`}
                alt="Find the Bearded Collie"
                onLoad={() => setLoadingMessage("Ready!")}
                onError={() => setLoadingMessage("Failed to Load Image")}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent closing modal when clicking on image

                  if (!gameStarted) return; // Prevent clicks before game starts

                  const rect = e.currentTarget.getBoundingClientRect();
                  const clickX = e.clientX - rect.left;
                  const clickY = e.clientY - rect.top;

                  const scaleX = 10240 / rect.width;
                  const scaleY = 5760 / rect.height;

                  const scaledX = clickX * scaleX;
                  const scaledY = clickY * scaleY;

                  console.log(`Scaled coordinates: X: ${scaledX}, Y: ${scaledY}`);
                  console.log(`Hotspot boundaries: X1: ${hotspot.x1}, X2: ${hotspot.x2}, Y1: ${hotspot.y1}, Y2: ${hotspot.y2}`);

                  if (
                    scaledX >= hotspot.x1 &&
                    scaledX <= hotspot.x2 &&
                    scaledY >= hotspot.y1 &&
                    scaledY <= hotspot.y2
                  ) {
                    setResult("Winner!");
                  } else {
                    setAttemptsLeft((prev) => {
                      const newAttempts = prev - 1;
                      if (newAttempts <= 0) {
                        setResult("Game Over");
                      }
                      return newAttempts;
                    });
                  }
                }}
                style={{
                  width: "10240px", // Full resolution width
                  height: "5760px", // Full resolution height
                  cursor: result ? "default" : "pointer",
                  display: "block",
                }}
              />
            </div>
          </div>
        )}

        {/* Result Section */}
        {result && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10001,
            }}
            onClick={() => {
              setShowLargeImage(false);
              setResult(null);
              setAttemptsLeft(3);
            }}
          >
            <h1
              style={{
                fontFamily: "'Press Start 2P', cursive",
                color: result === "Winner!" ? "#00FF00" : "#FF0000",
                fontSize: "48px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              {result}
            </h1>
            <Typography
              style={{
                fontFamily: "'Press Start 2P', cursive",
                color: "#FFFFFF",
                fontSize: "16px",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Hit (Esc) to exit
            </Typography>
          </div>
        )}




        <style>{`
  @keyframes spinAndPulse {
    0% {
      transform: rotate(0deg) scale(1);
      transform-origin: center;
    }
    50% {
      transform: rotate(180deg) scale(1.1);
      transform-origin: center;
    }
    100% {
      transform: rotate(360deg) scale(1);
      transform-origin: center;
    }
  }

  button:focus, button:active {
    outline: none;
    box-shadow: none;
    background: linear-gradient(145deg, #3f51b5, #283593);
  }

  button {
    -webkit-tap-highlight-color: transparent;
  }
`}</style>

      </div>
    </div>
  );
}


const DOG_BREEDS = [
  "Affenpinscher",
  "Afghan Hound",
  "Airedale Terrier",
  "Akita",
  "Alaskan Malamute",
  "American Bulldog",
  "American Eskimo Dog",
  "American Foxhound",
  "American Pit Bull Terrier",
  "American Staffordshire Terrier",
  "Anatolian Shepherd Dog",
  "Australian Cattle Dog",
  "Australian Shepherd",
  "Australian Terrier",
  "Basenji",
  "Basset Hound",
  "Beagle",
  "Bearded Collie",
  "Beauceron",
  "Bedlington Terrier",
  "Belgian Malinois",
  "Belgian Sheepdog",
  "Belgian Tervuren",
  "Bergamasco",
  "Berger Picard",
  "Bernese Mountain Dog",
  "Bichon Frise",
  "Black and Tan Coonhound",
  "Bloodhound",
  "Boerboel",
  "Border Collie",
  "Border Terrier",
  "Borzoi",
  "Boston Terrier",
  "Bouvier des Flandres",
  "Boxer",
  "Boykin Spaniel",
  "Briard",
  "Brittany",
  "Brussels Griffon",
  "Bull Terrier",
  "Bulldog",
  "Bullmastiff",
  "Cairn Terrier",
  "Canaan Dog",
  "Cane Corso",
  "Cardigan Welsh Corgi",
  "Cavalier King Charles Spaniel",
  "Chesapeake Bay Retriever",
  "Chihuahua",
  "Chinese Crested",
  "Chow Chow",
  "Clumber Spaniel",
  "Cocker Spaniel",
  "Collie",
  "Coton de Tulear",
  "Dachshund",
  "Dalmatian",
  "Dandie Dinmont Terrier",
  "Doberman Pinscher",
  "Dogue de Bordeaux",
  "English Cocker Spaniel",
  "English Foxhound",
  "English Setter",
  "English Springer Spaniel",
  "English Toy Spaniel",
  "Entlebucher Mountain Dog",
  "Field Spaniel",
  "Finnish Spitz",
  "Flat-Coated Retriever",
  "French Bulldog",
  "German Pinscher",
  "German Shepherd Dog",
  "German Shorthaired Pointer",
  "German Wirehaired Pointer",
  "Giant Schnauzer",
  "Glen of Imaal Terrier",
  "Golden Retriever",
  "Gordon Setter",
  "Great Dane",
  "Great Pyrenees",
  "Greater Swiss Mountain Dog",
  "Greyhound",
  "Harrier",
  "Havanese",
  "Ibizan Hound",
  "Icelandic Sheepdog",
  "Irish Setter",
  "Irish Terrier",
  "Irish Wolfhound",
  "Italian Greyhound",
  "Japanese Chin",
  "Keeshond",
  "Kerry Blue Terrier",
  "Komondor",
  "Kuvasz",
  "Labrador Retriever",
  "Labradoodle",
  "Lakeland Terrier",
  "Leonberger",
  "Lhasa Apso",
  "Lowchen",
  "Maltese",
  "Manchester Terrier",
  "Mastiff",
  "Miniature American Shepherd",
  "Miniature Pinscher",
  "Miniature Schnauzer",
  "Newfoundland",
  "Norfolk Terrier",
  "Norwegian Buhund",
  "Norwegian Elkhound",
  "Norwich Terrier",
  "Nova Scotia Duck Tolling Retriever",
  "Old English Sheepdog",
  "Otterhound",
  "Papillon",
  "Parson Russell Terrier",
  "Pekingese",
  "Pembroke Welsh Corgi",
  "Pharaoh Hound",
  "Plott (Plott Hound)",
  "Pointer",
  "Polish Lowland Sheepdog",
  "Portuguese Podengo Pequeno",
  "Portuguese Water Dog",
  "Pug",
  "Puli",
  "Pyrenean Mastiff",
  "Pyrenean Shepherd",
  "Rat Terrier",
  "Redbone Coonhound",
  "Rhodesian Ridgeback",
  "Rottweiler",
  "Russell Terrier",
  "Saint Bernard",
  "Saluki",
  "Samoyed",
  "Schipperke",
  "Scottish Deerhound",
  "Scottish Terrier",
  "Sealyham Terrier",
  "Segugio Italiano",
  "Shetland Sheepdog",
  "Shiba Inu",
  "Shih Tzu",
  "Siberian Husky",
  "Silky Terrier",
  "Skye Terrier",
  "Sloughi",
  "Smooth Fox Terrier",
  "Spanish Water Dog",
  "Spinone Italiano",
  "Stabyhoun",
  "Staffordshire Bull Terrier",
  "Standard Schnauzer",
  "Sussex Spaniel",
  "Swedish Lapphund",
  "Swedish Vallhund",
  "Tibetan Mastiff",
  "Tibetan Spaniel",
  "Tibetan Terrier",
  "Toy Fox Terrier",
  "Treeing Walker Coonhound",
  "Vizsla",
  "Weimaraner",
  "Welsh Springer Spaniel",
  "Welsh Terrier",
  "West Highland White Terrier",
  "Whippet",
  "Wire Fox Terrier",
  "Wirehaired Pointing Griffon",
  "Xoloitzcuintli",
  "Yorkshire Terrier",
  "Zuchon",
];

export default DogSprite;
