import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Home, Mail } from 'lucide-react';
import AuthPopup from './AuthPopup';
import PaymentPopup from './PaymentPopup';

const MEDIA_BASE_URL = "https://media.satoshiadvance.com/images";

const AboutPage = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [selectedOption, setSelectedOption] = useState('About');
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(15); // Default to regular subscription price

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleButtonClick = (config) => {
    if (!config) return;
    
    if (config.action === 'auth') {
      setShowAuthPopup(true);
    } else if (config.action === 'payment') {
      setShowPaymentPopup(true);
      // You can store the amount in state if needed
      setPaymentAmount(config.amount); // Store the correct payment amount
    } else if (config.url) {
      window.location.href = config.url;
    }
  };

  // All sections, including the new "Bit Dogs" and "Advertise"
  const sections = {
    About: [
      {
        image: `${MEDIA_BASE_URL}/RetroLandscape1.png`,
        title: "About Us",
        quote: "The best way to predict the future is to create it. - Peter Drucker",
        content: "Welcome to Satoshi Advance—your comprehensive resource for exploring cryptocurrency borrowing and lending opportunities. We make it easy for you to research and compare various protocols, lenders, and platforms that suit your financial needs. Our goal is to simplify the ever-evolving world of crypto finance, providing clarity and convenience along the way."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroLandscape2.png`,
        title: "Knowledge Is Power in Crypto Lending",
        quote: "The future depends on what you do today. - Mahatma Gandhi",
        content: "By taking charge of your financial future through crypto financing, you have the power to shape the outcomes you desire. We're here to support you on that journey. We believe that informed decisions lead to better financial outcomes."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroLandscape3.png`,
        title: "Our Mission: Bridging Borrowers and Lenders",
        quote: "",
        content: "Our mission is to seamlessly connect cryptocurrency borrowers and lenders. Satoshi Advance helps you make sense of rapid innovations in crypto financing, guiding you toward the best options for your goals."
      }
    ],
    "Bit Flip": [
      {
        image: `${MEDIA_BASE_URL}/RetroBitFlip1.png`,
        title: "Bitcoin or Satoshi",
        quote: "Rockets are cool. There’s no getting around that. - Elon Musk",
        content: "Play the Bit Flip Game today! Flick up to flip the coin. This coin flipping game transforms the classic flip of a coin into a modern, interactive experience with friends. Flip a virtual coin with a Bitcoin on one side and a Satoshi symbol on the other to decide who gets to break in a game of pool or who takes the backseat in the car, or who has to take out the trash next. Players must guess mid-flip, adding suspense and excitement to the decision-making process. It's an engaging way to bring a futuristic twist to the age-old tradition of making decisions by chance."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroBitFlip2.png`,
        title: "Guess a Side, Flip a coin with Friends",
        quote: "2000 years ago one man got nailed to a tree for saying how great it would be if everyone was nice to each other for a change. - Douglas Adams",
        content: "But the Bit Flip Game isn't just about flipping a coin with friends; it's about the experience. Play heads or tails with a virtual Bitcoin while swiping down to enjoy immersive music, swiping right to skip tracks, or swiping left to change the glow color. Personalization makes the game vibrant, dynamic, and entertaining. Challenge your friends to a best-of-three or best-of-five series and enjoy this reimagined version of a timeless activity. Just Swipe up to Flip and guess which side wins now!."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroBitFlip3.png`,
        title: "Pick a side, any side",
        quote: "If people like you, they’ll listen to you, but if they trust you, they’ll do business with you. - Zig Ziglar",
        content: "Beyond just being a game, Bit Flip is a unique way to connect and build trust. Flip a coin with friends and experience laughter, friendly competition, and fun. With its simple mechanics and engaging design, the game elevates flipping a virtual coin into a meaningful and memorable activity that everyone can enjoy together. Bit Flip with your friends today!"
      }
    ],
    "Bit Wheel": [
      {
        image: `${MEDIA_BASE_URL}/RetroBitWheel1.png`,
        title: "Spin Mechanics",
        quote: "“I got a fever, and the only prescription is more cowbell” - Christopher Walken",
        content: "21 is a competitive wheel-spinning game designed for two players, blending strategy and Bitcoin price dynamics. Players alternate spins to accumulate points over 10 rounds, with starting scores set at 21. The wheel is divided into 42 segments, representing distinct scoring opportunities, including standard segments, tie sections, and high-stakes bonus areas like 'Super Self' and 'Super Tie.' Bitcoin's 24-hour price movement determines the base spinning speed, creating a dynamic gaming experience influenced by real-world data. Optional features, such as manual speed control via swipe intensity, add further interactivity."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroBitWheel2.png`,
        title: "Spin to Win",
        quote: "“If you really want to understand something, the best way is to try and explain it to someone else.” – Douglas Adams",
        content: "Each spin costs one point, and landing on the player's side yields a base score of 21 points. The scoring becomes more exciting with special segments. Players can wager on outcomes like 'Tie' (50 points), 'Super Tie' (100 points), or 'Super Self' (500 points). While 'Super Tie' represents the ultimate midpoint between players, betting incorrectly on it results in a loss of points. Conversely, 'Self Win' and 'Super Self' sections, located in the center of each player's half of the wheel, provide lucrative rewards for precise bets. Gameplay is further enriched with visual features, including a display of the last 20 winning colors and real-time tracking of BTC price movements."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroBitWheel3.png`,
        title: "Gameplay Strategy and Technical Integration",
        quote: "“Focused, hard work is the real key to success.” – John Carmack",
        content: "Players must strategize their spins and bets to maximize points, considering both spin dynamics and potential segment rewards. Bitcoin price shifts directly influence spin speeds, ranging from 1x (slowest) to 20x (fastest), based on percentages of price change over the last 24 hours. The game's UI highlights critical gameplay elements such as current scores, remaining spins, and BTC data. Technically, the game relies on smooth wheel animations, accurate segment detection, and integration with a Bitcoin price API, making '21 : Bit Wheel Game' a unique blend of skill, luck, and real-time market influence."
      }
    ],
    "Bit Tanks": [
      {
        image: `${MEDIA_BASE_URL}/RetroBitTanks1.png`,
        title: "Aim and Fire",
        quote: "Empty your mind; be formless, shapeless – like water.” – Bruce Lee",
        content: "In Bit Tanks, the key to victory lies in your aim. Adjust your tank's angle higher or lower to line up the perfect shot. Set the velocity to control the power of your attack and watch your shot arc across the Bitcoin-driven terrain toward your opponent."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroBitTanks2.png`,
        title: "Dynamic Terrain",
        quote: "“Every strike brings me closer to the next home run.” – Babe Ruth",
        content: "The battlefield in Bit Tanks isn't static—it moves with Bitcoin's price action. The scrolling ground mimics real-time price fluctuations, adding a strategic layer to every shot. Stay sharp and adapt your tactics to the ever-changing terrain."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroBitTanks3.png`,
        title: "Two Hits to Win",
        quote: "“What you are thinking is what you are becoming.” – Muhammad Ali",
        content: "Victory requires precision and persistence. Each tank can withstand one hit, but the second strike seals the game. Outmaneuver your opponent, land two solid hits, and claim your place as the ultimate Bit Tanks champion!"
      }
    ],
    
    // 6th Section: Bit Dogs
    "Bit Dogs": [
      {
        image: `${MEDIA_BASE_URL}/RetroSubscribe3.png`,
        title: "Retro Dog Breeds & 8-Bit Beginnings",
        quote: "“Dogs are better than human beings because they know but do not tell.” – Emily Dickinson",
        content: "Explore a nostalgic world of dog breeds in playful pixel-art fashion. Learn fun facts about everything from Bulldogs to Beagles in a retro-inspired format. (Placeholder text to be replaced with detailed breed info and unique retro styling.)"
      },
      {
        image: `${MEDIA_BASE_URL}/RetroSubscribe2.png`,
        title: "Chiptune Music & Wagging Tails",
        quote: "“Money can buy you a fine dog, but only love can make him wag his tail.” – Kinky Friedman",
        content: "Immerse yourself in classic chiptune melodies while you discover the charming quirks of your favorite breeds. (Placeholder text for dog breed spotlights, retro music streaming ideas, etc.)"
      },
      {
        image: `${MEDIA_BASE_URL}/RetroSubscribe1.png`,
        title: "Find the Bearded Collie Challenge",
        quote: "“Happiness is a warm puppy.” – Charles M. Schulz",
        content: "Test your spotting skills in our retro-pixel environment. Race your friends to see who can locate the Bearded Collie the fastest among the crowd of pixel pups! (Placeholder text for interactive challenge details.)"
      }
    ],

    Subscribe: [
      {
        image: `${MEDIA_BASE_URL}/RetroSubscribe1.png`,
        title: "Free Subscription: Lending & Borrowing Made Simple",
        quote: "“An entrepreneur is someone who has a vision for something and a want to create.” — David Karp",
        content: "Join SatoshiAdvance.com for free and take your first step into the world of decentralized lending and borrowing. With a free subscription, you can create a personalized profile and gain access to basic tools for managing and exploring cryptocurrency loans. Stay informed with curated updates and insights tailored to help you grow and manage your digital assets. All it takes is your email to sign up—no hidden costs, no obligations. Start building your crypto knowledge and connections today."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroSubscribe2.png`,
        title: "Premium Access: Unlimited Features & Mini Games",
        quote: "“I may not have gone where I intended to go, but I think I have ended up where I needed to be.” -- Douglas Adams",
        content: "Unlock the full potential of SatoshiAdvance.com with a one-time payment of $15. This premium subscription includes everything in the free tier, plus exclusive content, access to unlimited mini-games, and the ability to game with your friends in an exciting and interactive environment. Whether you're exploring new strategies in lending and borrowing or diving into fun and competitive gaming experiences, the premium tier offers unparalleled value and engagement for crypto enthusiasts of all levels."
      },
      {
        image: `${MEDIA_BASE_URL}/RetroSubscribe3.png`,
        title: "Donor Membership: Support Growth, Education, & Entertainment",
        quote: "“If you just work on stuff that you like and you’re passionate about, you don’t have to have a master plan with how things will play out.” — Mark Zuckerberg",
        content: "For those passionate about growing and giving back to the crypto community, becoming a donor offers a meaningful way to support SatoshiAdvance.com. Your contribution will help expand our platform, enhance educational resources, and create even more engaging games and tools for users worldwide. Donor memberships empower us to reach and inspire more people, fostering a deeper understanding of blockchain technology while delivering fun and accessible experiences. Join us as a valued supporter and help shape the future of crypto innovation and entertainment."
      }
    ],
    // 7th Section: Advertise
    "Advertise": [
      {
        image: `${MEDIA_BASE_URL}/RetroAdvertise1.png`,
        title: "Advertise",
        quote: "“Stopping advertising to save money is like stopping your watch to save time.” – Henry Ford",
        content: "Contact us directly via email at contact@satoshiadvance.com to learn more about advertisement opportunities such as ad placement and article promotion. (Placeholder text for your own unique advertisement details.)"
      }
    ]
  };

  const dynamicImage = {
    About: `${MEDIA_BASE_URL}/RetroLandscape4.png`,
    "Bit Flip": {
      static: `${MEDIA_BASE_URL}/RetroBitFlip4.png`,
      animated: "/images/BunnyTest1.gif"  // Local path to your GIF
    },
    "Bit Wheel": `${MEDIA_BASE_URL}/RetroBitWheel4.png`,
    "Bit Tanks": `${MEDIA_BASE_URL}/RetroBitTanks4.png`,
    Subscribe: `${MEDIA_BASE_URL}/RetroSubscribe4.png`,
    // Dynamic images for the new sections
    "Bit Dogs": `${MEDIA_BASE_URL}/RetroSubscribe4.png`,
    "Advertise": `${MEDIA_BASE_URL}/RetroBitTanks4.png`
  };

  const handleHomeClick = () => {
    window.location.href = '/';  // Navigate to home page
  };

  const HeaderBar = () => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      marginBottom: '2rem',
      padding: '0 0.5rem',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap', // Allow items to wrap
        gap: isDesktop ? '2rem' : '0.5rem', // Smaller gap for mobile
        backgroundColor: 'white',
        padding: isDesktop ? '1rem' : '0.75rem',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: isDesktop ? '1rem' : '0.75rem',
      }}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            minWidth: isDesktop ? 'auto' : '28%', // Set width for mobile layout
            justifyContent: 'center', // Center the content
          }}
          onClick={handleHomeClick}
        >
          <Home size={isDesktop ? 24 : 18} />
          <span>Home</span>
        </motion.div>

        {Object.keys(sections).map((option) => (
          <motion.div
            key={option}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={{
              cursor: 'pointer',
              fontWeight: selectedOption === option ? 'bold' : 'normal',
              textDecoration: selectedOption === option ? 'underline' : 'none',
              minWidth: isDesktop ? 'auto' : '28%', // Set width for mobile layout
              textAlign: 'center', // Center the text
              padding: isDesktop ? '0' : '0.25rem', // Add some padding on mobile
            }}
            onClick={() => setSelectedOption(option)}
          >
            {option}
          </motion.div>
        ))}
      </div>
    </div>
  );

  const getButtonConfig = (title) => {
    const sectionTitle = title.toLowerCase();

    // Game URLs and subscription actions
    const gameUrls = {
      'bitcoin or satoshi': { url: 'https://flip.satoshiadvance.com', label: 'Play Now' },
      'guess a side, flip a coin with friends': { url: 'https://flip.satoshiadvance.com', label: 'Play Now' },
      'pick a side, any side': { url: 'https://flip.satoshiadvance.com', label: 'Play Now' },
      'spin mechanics': { url: 'https://wheel.satoshiadvance.com', label: 'Play Now' },
      'spin to win': { url: 'https://wheel.satoshiadvance.com', label: 'Play Now' },
      'gameplay strategy and technical integration': { url: 'https://wheel.satoshiadvance.com', label: 'Play Now' },
      'aim and fire': { url: 'https://tanks.satoshiadvance.com', label: 'Play Now' },
      'dynamic terrain': { url: 'https://tanks.satoshiadvance.com', label: 'Play Now' },
      'two hits to win': { url: 'https://tanks.satoshiadvance.com', label: 'Play Now' },
      // Subscription sections
      'free subscription: lending & borrowing made simple': { action: 'auth', label: 'Subscribe' },
      'premium access: unlimited features & mini games': { action: 'payment', label: 'Upgrade Subscription' },
      'donor membership: support growth, education, & entertainment': { action: 'payment', label: 'Donor Subscription', amount: 50 }
    };

    return gameUrls[sectionTitle] || null;
  };

  const Section = ({ image, title, quote, content }) => {
    const buttonConfig = getButtonConfig(title);

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          backgroundColor: 'white',
          padding: '1.5rem',
          borderRadius: '10px',
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
          marginBottom: '2rem',
          textAlign: 'left',
          maxWidth: '800px',
          margin: '0 auto',
          minHeight: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <img
          src={image.startsWith('http') ? image : `/images/${image}`}
          alt={title}
          style={{
            width: '95%',
            height: 'auto',
            borderRadius: '10px',
            marginBottom: '1rem',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <h2 style={{ fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '0.5rem', textAlign: 'center' }}>
          {title}
        </h2>
        <blockquote style={{ fontStyle: 'italic', marginBottom: '1rem', color: '#666', textAlign: 'center' }}>
          {quote}
        </blockquote>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', textAlign: 'center' }}>{content}</p>

        {buttonConfig && (
          <motion.button
            onClick={() => handleButtonClick(buttonConfig)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              display: 'inline-block',
              backgroundColor: '#0066cc',
              color: 'white',
              padding: '0.75rem 2rem',
              borderRadius: '8px',
              textDecoration: 'none',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              marginTop: '1.5rem',
              textAlign: 'center',
              alignSelf: 'center',
              cursor: 'pointer',
              border: 'none',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease'
            }}
          >
            {buttonConfig.label}
          </motion.button>
        )}
      </motion.div>
    );
  };

  const ContactSection = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      style={{
        backgroundColor: 'white',
        padding: '1rem',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        marginTop: '2rem',
        textAlign: 'center',
        maxWidth: '800px',
        margin: '2rem auto',
      }}
    >
      {/* Navigation Bar */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap', // Ensure it wraps on mobile
        gap: '1rem', // Smaller gap for mobile
        marginBottom: '2rem',
        padding: '1rem',
        backgroundColor: '#f8f9fa',
        borderRadius: '12px',
        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.05)',
      }}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            padding: '0.5rem 1rem',
            fontSize: '1rem',
            textAlign: 'center',
            color: '#0066cc',
          }}
          onClick={handleHomeClick}
        >
          <Home size={20} />
          <span>Home</span>
        </motion.div>

        {Object.keys(sections).map((option) => (
          <motion.div
            key={option}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            style={{
              cursor: 'pointer',
              fontWeight: '600',
              textDecoration: selectedOption === option ? 'underline' : 'none',
              color: selectedOption === option ? '#0066cc' : '#333',
              fontSize: '1rem',
              padding: '0.5rem 1rem',
              textAlign: 'center',
            }}
            onClick={() => setSelectedOption(option)}
          >
            {option}
          </motion.div>
        ))}
      </div>

      {/* Email Button */}
      <div style={{ marginBottom: '1.5rem' }}>
        <motion.a
          href="mailto:contact@satoshiadvance.com"
          whileHover={{ scale: 1.05, backgroundColor: '#005bb7' }}
          whileTap={{ scale: 0.95 }}
          style={{
            display: 'block',
            maxWidth: '300px', // Responsive width for email button
            margin: '0 auto',
            backgroundColor: '#0066cc',
            color: 'white',
            padding: '1rem',
            borderRadius: '12px',
            fontSize: '1rem',
            fontWeight: '600',
            textAlign: 'center',
            textDecoration: 'none',
            boxShadow: '0 4px 12px rgba(0, 102, 204, 0.2)',
            letterSpacing: '0.5px',
            transition: 'all 0.3s ease',
          }}
        >
          contact@satoshiadvance.com
        </motion.a>
      </div>

      {/* Get in Touch Title */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.75rem',
        backgroundColor: '#f8f9fa',
        padding: '1rem',
        borderRadius: '12px',
        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.05)',
      }}>
        <Mail size={28} style={{ color: '#0066cc' }} />
        <h3 style={{
          fontSize: '1.2rem',
          fontWeight: '700',
          margin: '0',
          color: '#333',
          letterSpacing: '0.5px',
          textAlign: 'center',
        }}>
          Get in Touch!
        </h3>
      </div>
    </motion.div>
  );

  const HoverImage = ({ media, alt }) => {
    const [isHovering, setIsHovering] = useState(false);

    const getImageSource = (media) => {
      if (typeof media === 'string') {
        return media;
      }
      return isHovering ? media.animated : media.static;
    };

    return (
      <img
        src={getImageSource(media)}
        alt={alt}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
          width: isDesktop ? '35%' : '100%',
          maxWidth: isDesktop ? '1900px' : '800px',
          borderRadius: '10px',
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
          margin: '0 auto',
          transition: 'transform 0.3s ease-in-out',
          transform: isHovering ? 'scale(1.02)' : 'scale(1)',
        }}
      />
    );
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '2rem',
        overflowY: 'auto',
        height: 'auto',
      }}
    >
      <HeaderBar />

      {sections[selectedOption].map((section, index) => (
        <Section key={index} {...section} />
      ))}

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{
          marginTop: '2rem',
          textAlign: 'center',
        }}
      >
        <HoverImage
          media={dynamicImage[selectedOption]}
          alt="Dynamic Section"
        />
      </motion.div>

      <ContactSection />

      {showAuthPopup && <AuthPopup onClose={() => setShowAuthPopup(false)} />}
      {showPaymentPopup && (
        <PaymentPopup
          onClose={() => setShowPaymentPopup(false)}
          amount={paymentAmount}
        />
      )}
    </div>
  );
};

export default AboutPage;
