import React, { useState } from 'react';
import { Box, Typography, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

// Constants for Media Base URL
const MEDIA_BASE_URL = "https://media.satoshiadvance.com/images";

// Title Styling
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  fontSize: '2.4rem',
  marginTop: '40px',
  marginBottom: '20px',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  '@media (max-width: 600px)': {
    fontSize: '1.2rem',
  },
}));

// Orange Divider Styling
const OrangeDividerBar = styled(motion('div'))(({ theme }) => ({
  width: '80%',
  height: '3.5px',
  backgroundColor: '#FF6B00', // Rich orange color
  margin: '30px auto 30px',
  transformOrigin: 'center',
  position: 'relative',
  zIndex: 1,
  boxShadow: `
    0 0 5px #FF8F00,
    0 0 10px #FF6B00,
    0 0 20px #FF6B00,
    0 0 30px #FF4500,
    0 0 50px #FF4500
  `,
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

// Blog Card Container
const BlogCard = styled(motion(Box))(({ theme }) => ({
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '16px',
  marginBottom: '20px',
  color: '#FFFFFF',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',

  background: `
    linear-gradient(135deg, 
      rgba(26, 35, 126, 0.95) 0%,
      rgba(21, 101, 192, 0.9) 100%
    )
  `,

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.03) 0px,
        rgba(255, 255, 255, 0.03) 1px,
        transparent 1px,
        transparent 2px
      )
    `,
    pointerEvents: 'none',
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.05), transparent)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.5s ease',
  },

  boxShadow: '0 4px 15px rgba(0,0,0,0.2), inset 0 0 30px rgba(26,35,126,0.2)',

  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 25px rgba(0,0,0,0.3), inset 0 0 35px rgba(26,35,126,0.3)',
    '&:after': {
      transform: 'translateX(100%)',
      transition: 'transform 1s ease',
    }
  },

  [theme.breakpoints.down('sm')]: {
    padding: '12px',
    borderRadius: '8px',
  }
}));

const BlogImage = styled('img')(({ theme }) => ({
  width: '300px',
  height: '180px',
  objectFit: 'cover',
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    width: '160px',
    height: '120px',
  }
}));

const BlogTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  fontWeight: 'bold',
  color: '#FFFFFF',
  marginBottom: '8px',
  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
  }
}));

const BlogMetadata = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#FFE873',
  marginBottom: '8px',
  textShadow: '0 0 8px rgba(255,216,0,0.3)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  }
}));

const BlogPreview = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: 'rgba(255, 255, 255, 0.9)',
  marginTop: '12px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  }
}));

const NewsAndBlogs = () => {
  const [expandedId, setExpandedId] = useState(null);
  const navigate = useNavigate();

  const blogs = [
    {
      id: 1,
      title: "World Liberty Financial released Worldwide",
      date: "January, 2025",
      tagline: "Defi & Investments",
      preview: "President Donald J. Trump & sons release revolutionary WLFI Token Sale in 2024",
      firstParagraph: "Welcome to Satoshi Advance News, where we spotlight the boldest trends pushing the boundaries of bitcoin and crypto financing. Get ready for an adrenaline-charged journey into the 2024 launch of Donald Trump’s World Liberty Financial (WLFI) token,",
      image: `${MEDIA_BASE_URL}/NewsAndBlogs1.png`,
      slug: "world-liberty-financial"
    },
    {
      id: 2,
      title: "Why Dogecoin Will Go to the Moon (or at Least $10)",
      date: "Feb 1, 2025",
      tagline: "Crypto & Memecoins",
      preview: "The original memecoin that keeps on winning—could Dogecoin reach $10?",
      firstParagraph: "If there’s one cryptocurrency that can put a genuine smile on your face—and possibly make your wallet grin too—it’s Dogecoin. As the original memecoin and undeniably the most adorable digital currency around, Dogecoin has charmed the crypto world with its trademark Shiba Inu logo...",
      image: `${MEDIA_BASE_URL}/NewsAndBlogs2.png`,
      slug: "dogecoin-moon"
    },
    {
      id: 3,
      title: "NFT Gaming Revolution",
      date: "Dec 13, 2024",
      tagline: "Gaming & NFTs",
      preview: "How NFT gaming is transforming the gaming industry and creating new opportunities for players and developers...",
      firstParagraph: "Full article content goes here... This would be the complete blog post that shows when expanded.",
      image: `${MEDIA_BASE_URL}/NewsAndBlogs3.png`,
    },
    {
      id: 4,
      title: "Crypto Regulation Updates",
      date: "Dec 12, 2024",
      tagline: "Regulatory News",
      preview: "Latest updates on cryptocurrency regulations and their impact on the global digital asset marketplace...",
      firstParagraph: "Full article content goes here... This would be the complete blog post that shows when expanded.",
      image: `${MEDIA_BASE_URL}/NewsAndBlogs4.png`,
    },
  ];

  const handleCardClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleReadMore = (blogId, blogSlug) => {
    // Old:
    // navigate(`/article/${blogSlug}`);
  
    // New: use /info-center/<slug> 
    navigate(`/info-center/${blogSlug}`);
  };

  return (
    <div>
      <SectionTitle>
        News & Blogs
      </SectionTitle>

      <OrangeDividerBar
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{
          duration: 2,
          ease: "easeInOut",
        }}
      />

      <Box sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
        gap: { xs: 2, md: 3 },
        padding: { xs: 2, md: 4 },
        maxWidth: '1400px',
        margin: '0 auto'
      }}>
        {blogs.map((blog, index) => (
          <BlogCard
            key={blog.id}
            onClick={() => handleCardClick(blog.id)}
            initial={{ opacity: 0, x: index % 2 === 0 ? '-100vw' : '100vw', rotateY: -90 }}
            animate={{ opacity: 1, x: 0, rotateY: 0 }}
            transition={{ duration: 1, delay: index * 0.3, type: 'spring' }}
            sx={{
              height: expandedId === blog.id ? 'auto' : undefined,
              transition: 'all 0.3s ease-in-out'
            }}
          >
            {/* Title and Metadata always visible */}
            <Box sx={{ flex: 1 }}>
              <BlogTitle>{blog.title}</BlogTitle>
              <BlogMetadata>{blog.tagline} • {blog.date}</BlogMetadata>
            </Box>

            {/* Content to show when expanded */}
            <motion.div
              initial={false}
              animate={{
                height: expandedId === blog.id ? 'auto' : 0,
                opacity: expandedId === blog.id ? 1 : 0
              }}
              transition={{ duration: 0.3 }}
              style={{ overflow: 'hidden' }}
            >
              {/* Blog Image, visible only when expanded */}
              {expandedId === blog.id && (
                <BlogImage src={blog.image} alt={blog.title} />
              )}

              <Box sx={{
                mt: 3,
                p: 3,
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '8px',
                transform: expandedId === blog.id ? 'translateY(0)' : 'translateY(-20px)',
                transition: 'transform 0.3s ease-in-out'
              }}>
                <Typography sx={{
                  color: '#FFFFFF',
                  lineHeight: 1.6,
                  fontSize: '1.1rem'
                }}>
                  {blog.firstParagraph}
                </Typography>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReadMore(blog.id, blog.slug);
                  }}
                  sx={{
                    color: '#FFE873',
                    mt: 2,
                    cursor: 'pointer',
                    '&:hover': { opacity: 0.8 }
                  }}
                >
                  Read More...
                </Box>
              </Box>

            </motion.div>

            {/* Blog preview always visible unless expanded */}
            {!expandedId || expandedId !== blog.id ? (
              <BlogPreview>
                {blog.preview}
                <Box sx={{
                  color: '#FFE873',
                  mt: 1,
                  cursor: 'pointer',
                  '&:hover': { opacity: 0.8 }
                }}>
                  ...
                </Box>
              </BlogPreview>
            ) : null}
          </BlogCard>
        ))}

      </Box>
    </div>
  );
};

export default NewsAndBlogs;
