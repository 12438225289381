import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { MotionDivider } from './PriceDisplay';
import {
  Coins,
  Wallet,
  CircuitBoard,
  BarChart3,
  Globe2,
  Gem,
  Building2
} from 'lucide-react';


const categoryIcons = {
  'DeFi': Coins,
  'Wallet': Wallet,
  'NFT': Gem,
  'Media': BarChart3,
  'Bitcoin': CircuitBoard,
  'USA': Building2,
  'Other': Globe2,
};


const CategoryText = styled('div')({
  color: 'rgba(255, 255, 255, 0.9)',
  fontSize: '0.9rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  marginBottom: '8px',
  textAlign: 'center'
});

const StyledLink = styled(motion.a)({  // Changed from styled('a') to styled(motion.a)
  color: '#90EE90',
  textDecoration: 'none',
  fontSize: '1.1rem',
  display: 'block',
  fontWeight: 'bold',
  textShadow: '0 0 5px rgba(144, 238, 144, 0.5)',
  marginBottom: '4px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  width: '100%',
  perspective: '1000px',  // Add perspective for 3D effect
  '&:hover': {
    color: '#98FB98',
  }
});

const ContentContainer = styled('div')({
  flex: 1,
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',  // Center horizontally
  textAlign: 'center',
});

const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  fontWeight: 'bold',
  fontSize: '2.5rem',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive",
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const GreenDividerBar = styled(motion('div'))(({ theme }) => ({
  width: '80%',
  height: '4px',
  backgroundColor: '#FFFFFF',
  margin: '20px auto 0',
  marginBottom: '30px',
  transformOrigin: 'center',
  // Adjusted to a richer green glow to match links
  boxShadow: `
    0 0 10px #FFFFFF,
    0 0 20px #00FF00, /* Richer green glow */
    0 0 30px #00FF00,
    0 0 40px #00FF00,
    0 0 70px #FFFFFF, /* White glow */
    0 0 80px #FFFFFF,
    0 0 100px #00FF00,
    0 0 150px #00FF00
  `,
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

// Create the green version of the MotionDivider component
export const GreenMotionDivider = () => {
  return (
    <GreenDividerBar
      initial={{ scaleX: 0 }}
      animate={{
        scaleX: 1,
        rotateX: 15,
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
      }}
    />
  );
};

const PopularLinksContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #2947B3 0%, #1e3178 100%)',  // Adjusted to match the image blues
  padding: '20px',
  borderRadius: '10px',
  width: '45%',
  boxShadow: '0 8px 32px 0 rgba(25, 44, 130, 0.37)',  // Adjusted to blue shadow
  backdropFilter: 'blur(4px)',
  margin: '0 auto',
  marginTop: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'left',
  [theme.breakpoints.down('lg')]: {
    width: '70%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '94%',
    margin: '10px auto',
    padding: '8px',
  }
}));

const LinkCard = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(41, 71, 179, 0.3) 0%, rgba(30, 49, 120, 0.2) 100%)',
  border: '2px solid rgba(255, 255, 255, 0.4)',  // Changed to white border
  borderRadius: '8px',
  padding: '15px',
  marginBottom: '15px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '15px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    borderColor: 'rgba(255, 255, 255, 0.8)',
    backgroundColor: 'rgba(41, 71, 179, 0.4)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  }
}));

const Description = styled('div')({
  color: 'rgba(255, 255, 255, 0.8)',
  fontSize: '1.1rem',  // Increased from 0.9rem to 1.1rem
  lineHeight: '1.5',   // Slightly increased line height for better readability
  marginBottom: '8px',
  whiteSpace: 'normal',
  overflow: 'visible',
  display: 'block',
  padding: '4px 0',    // Added some vertical padding for better spacing
});

const Divider = styled('div')({
  borderBottom: '1px dotted rgba(255, 255, 255, 0.3)',
  margin: '8px 0',
  width: '100%'
});

const CategoryIcon = styled(motion.div)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  border: '2px solid rgba(255, 255, 255, 0.4)',  // Added border
  flexShrink: 0,
});

const PopularLinks = () => {
  const [links, setLinks] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get('/api/popular-links');
        console.log('API Response:', response.data); // For debugging
        setLinks(response.data);
      } catch (error) {
        console.error('Error fetching popular links:', error);
      }
    };

    fetchLinks();
  }, []);

  const cardVariants = {
    initial: {
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    animate: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    hover: {
      scale: 1.02,
      transition: {
        duration: 0.2
      }
    }
  };

  const iconVariants = {
    initial: {
      rotate: 0
    },
    hover: {
      rotate: 360,
      transition: {
        duration: 2,
        ease: "linear",
      }
    }
  };

  if (links.length === 0) {
    return <Typography sx={{ color: '#FFFFFF' }}>Loading...</Typography>;
  }

  return (
    <>
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <RetroText>Popular Links</RetroText>
        <GreenMotionDivider />
      </Box>
      <PopularLinksContainer>
        {links.slice(0, 10).map((link, index) => {
          const IconComponent = categoryIcons[link.category] || categoryIcons['Other'];

          return (
            <div key={link._id}>
              <LinkCard
                variants={cardVariants}
                initial="initial"
                animate="animate"
                whileHover="hover"
                onHoverStart={() => setHoveredIndex(index)}
                onHoverEnd={() => setHoveredIndex(null)}
                onDoubleClick={() => window.open(link.URL, '_blank', 'noopener,noreferrer')}
                style={{ cursor: 'pointer' }}
              >
                <CategoryIcon
                  variants={iconVariants}
                  initial="initial"
                  whileHover="hover"
                >
                  <IconComponent size={24} color="rgba(255, 255, 255, 0.9)" />
                </CategoryIcon>

                <ContentContainer>
                  <CategoryText>
                    {link.category || 'Other'}
                  </CategoryText>
                  <StyledLink
                    href={link.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{
                      rotateY: 360,  // Full rotation on Y axis
                      transition: {
                        duration: 0.8,
                        ease: "easeInOut"
                      }
                    }}
                    whileTap={{
                      scale: 0.95,
                      rotateY: 360,
                      transition: {
                        duration: 0.5
                      }
                    }}
                  >
                    {link.URL}
                  </StyledLink>
                  <Divider />
                  {link.description && (
                    <Description>
                      {link.description}
                    </Description>
                  )}
                </ContentContainer>

                <CategoryIcon
                  variants={iconVariants}
                  initial="initial"
                  whileHover="hover"
                >
                  <IconComponent size={24} color="rgba(255, 255, 255, 0.9)" />
                </CategoryIcon>
              </LinkCard>
            </div>
          );
        })}
      </PopularLinksContainer>
    </>
  );
};

export default PopularLinks;

