import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

// Container for the entire component
const EducationLinksContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '16px',
  margin: '20px auto',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '20px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
  backgroundImage: 'linear-gradient(to bottom, #283593, #1a237e)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

// Individual section container
const SectionContainer = styled(Box)({
  backgroundColor: '#283593',
  padding: '15px',
  borderRadius: '12px',
  color: '#FFFFFF',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  backgroundImage: 'linear-gradient(to bottom, rgba(40, 53, 147, 0.9), rgba(26, 35, 126, 0.9))',
});

// Link container
const LinkContainer = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  '& p': {
    margin: 0,
    fontSize: '0.9rem',
    color: '#D1C4E9',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)',
  },
  [theme.breakpoints.down('sm')]: {
    '& p': {
      fontSize: '0.8rem',
    },
  },
}));

// Motion styles
const MotionLink = styled(motion.a)(({ theme }) => ({
  color: '#FFFF66',
  textDecoration: 'none',
  fontWeight: 'bold',
  display: 'block',
  marginBottom: '5px',
  fontSize: '1.25rem',  // Add this line for larger desktop font
  textShadow: '0px 1px 5px rgba(255, 255, 102, 0.8)',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',  // Add this line for smaller mobile font
  },
}));

const MotionLinkCard = styled(motion.div)({
  color: '#FFFF66', // Neon yellow
  fontWeight: 'bold',
  cursor: 'pointer',
  display: 'block',
  textShadow: '0px 1px 5px rgba(255, 255, 102, 0.8)',
  padding: '10px',
  borderRadius: '8px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
});

const MotionSection = styled(motion.div)({
  position: 'relative',
});

// Lens flare effect
const LensFlare = styled(motion.div)({
  position: 'absolute',
  top: '-10%',
  left: '-10%',
  width: '150%',
  height: '150%',
  background: 'radial-gradient(circle, rgba(255, 255, 102, 0.3), transparent)',
  filter: 'blur(20px)',
  opacity: 0.5,
  pointerEvents: 'none',
});

const EducationLinks = () => {
  const sections = [
    {
      title: 'Wallets & Exchanges',
      links: [
        { name: 'Gemini Exchange', url: 'https://www.gemini.com', description: 'Leading Exchange & Crypto Institution in US' },
        { name: 'Crypto.com', url: 'https://www.crypto.com', description: 'Leading Exchange' },
        { name: 'Coinbase', url: 'https://www.coinbase.com', description: 'Largest Exchange' },
        { name: 'Binance', url: 'https://www.binance.us', description: 'Leading Exchange & Crypto Institution' },
        { name: 'Metamask', url: 'https://www.metamask.com', description: 'Leading Defi Wallet' },
      ],
    },
    {
      title: 'Popular Apps',
      links: [
        { name: 'Uniswap', url: 'https://www.uniswap.org', description: 'Home of decentralized finance & beyond.' },
        { name: 'Rarible', url: 'https://www.rarible.com', description: 'Leading NFT Marketplace' },
        { name: 'Star Atlas', url: 'https://staratlas.com/', description: 'Web3 Space Exploration' },
        { name: 'dYdX', url: 'https://www.dydx.exchange', description: 'Innovative & Emerging Trading' },
        { name: 'Dextools', url: 'https://www.dextools.io', description: 'Defi trading resources' },
      ],
    },
    {
      title: 'Learning',
      links: [
        { name: 'Defi Lending', url: 'https://youtu.be/pp9Abjlz7K4?si=RPuLzquvV-dG6va1', description: 'Evolution of Defi Lending' },
        { name: 'Binance Academy', url: 'https://academy.binance.com', description: 'Crypto Finance Education' },
        { name: 'Investopediea', url: 'https://www.investopedia.com/cryptocurrency-4427699', description: 'Crypto Basic' },
        { name: 'Bitcoin Whitepaper', url: 'https://bitcoin.org/bitcoin.pdf', description: 'Mechanics & Origin of BTC Protocol' },
        { name: 'Buy Bitcoin', url: 'https://www.fidelity.com/crypto', description: 'Easy & Secure bitcoin acquisition' },
      ],
    },
  ];
  
  const handleDoubleClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <EducationLinksContainer>
      {sections.map((section, index) => (
        <SectionContainer key={index}>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: '1.3rem',
              marginBottom: '12px',
              textAlign: 'center',
              textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            {section.title}
          </Typography>
          {section.links.map((link, idx) => (
            <LinkContainer key={idx}>
              <MotionLink
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ rotate: [0, 5, -5, 0], scale: 1.15 }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
              >
                {link.name}
              </MotionLink>
              <Typography>{link.description}</Typography>
            </LinkContainer>
          ))}
        </SectionContainer>
      ))}
    </EducationLinksContainer>
  );
};

export default EducationLinks;
