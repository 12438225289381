// src/components/PriceDisplay.js

import React, { useState, useEffect, useRef } from 'react';
import { Box, Snackbar, Typography, IconButton, TextField, Button, Link, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import axios from 'axios';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import TopCryptoLoans from './TopCryptoLoans';
import PopularLinks from './PopularLinks';
import PaymentPopup from './PaymentPopup';
import AuthPopup from './AuthPopup';
import UsdVsBtcComparison from './UsdVsBtcComparison';
import UsdToBtcConverter from './UsdToBtcConverter';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import EducationLinks from './EducationLinks';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LenderBorrowerApply from './LenderBorrowerApply';
import NewsAndBlogs from './NewsAndBlogs';
import PlayCoinGames from './PlayCoinGames';
import DogSprite from './DogSprite';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

const AUDIO_BASE_URL = "https://media.satoshiadvance.com/audio";

// Optional: store your Ticker text for TTS here.
const TICKER_TEXT = `
          Welcome to Satoshi Advance Crypto Loans, news, and entertainment!
          Bitcoin All Time High $108,800 January, 2025! 
          Borrow, Lend, Shop, & more in DOGE, BTC & E T H.
          DOGE All time High $0.74 May, 2021!  
          E T H All Time High $4,7250 November, 2021!            
          Play Dog Cards, learn about dog breeds & download your favorite card here.
          As of December 2024, 10,000 Satoshi's are worth about $10!
          Good Luck & Explore    
`;

const DOG_TUNES = [
  "DogTunes1.wav",
  "DogTunes2.wav",
  "DogTunes3.wav",
  "DogTunes4.wav",
  "DogTunes5.wav",
  "DogTunes6.wav",
  "DogTunes7.wav",
  "DogTunes8.wav",
  "DogTunes9.wav",
  "DogTunes10.wav",
  "DogTunes11.mp3",
];

// Retro button with ! and ? signs styled as a white box with shaded blue interior
const RetroSquareButton = styled('div')(({ theme }) => ({
  // Base styles (tablet)
  width: '60px',
  height: '60px',
  backgroundColor: '#1a237e',
  border: '4px solid #FFFFFF',
  color: '#FFFFFF',
  fontSize: '2rem',
  fontFamily: "'Press Start 2P', cursive",
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#283593',
  },
  position: 'fixed',
  zIndex: 100000,

  // Mobile styles (55% smaller)
  [theme.breakpoints.down('sm')]: {
    width: '27px',  // 60px * 0.45 (45% of original size)
    height: '27px', // 60px * 0.45
    fontSize: '0.9rem', // Reduced font size for mobile
    border: '2px solid #FFFFFF', // Thinner border for mobile
  },

  // Desktop/widescreen styles (100% bigger)
  [theme.breakpoints.up('lg')]: {
    width: '120px',  // 60px * 2
    height: '120px', // 60px * 2
    fontSize: '4rem', // Larger font size for desktop
    border: '6px solid #FFFFFF', // Thicker border for desktop
  }
}));

const Separator = styled('span')({
  color: '#fff',
  margin: '0 8px',
});

// Add these with your other styled components
const TickerTape = styled('div')(({ theme }) => ({
  width: '80%',
  margin: '0 auto',
  marginTop: '10px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#FFFFFF',
  padding: '8px 0',
  backgroundColor: '#283593',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    fontSize: '16px'
  }
}));

const TickerContent = styled('div')({
  display: 'inline-block',
  animation: 'ticker 45s linear infinite',
  paddingLeft: '100%',
  '& a': {
    color: '#00BFFF',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#00BFFF'
    }
  },
  '&:hover': {
    animationPlayState: 'paused'
  },
  '@keyframes ticker': {
    '0%': {
      transform: 'translateX(0)'
    },
    '100%': {
      transform: 'translateX(-100%)'
    }
  }
});

const RetroButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  color: '#FFFFFF',
  backgroundColor: 'transparent',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '4px 12px',
  fontSize: '0.7rem',
  textTransform: 'none',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0 0 10px #00BFFF'
  },
  transition: 'all 0.3s ease'
}));

const PaymentButton = ({ onClick }) => {
  const payAudioRef = useRef(null);

  const playPaySound = (e) => {
    e.preventDefault();
    if (payAudioRef.current) {
      payAudioRef.current.currentTime = 0;
      payAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playPaySound(e);
    onClick(); // This triggers the popup
  };

  return (
    <>
      <audio ref={payAudioRef} src={`${AUDIO_BASE_URL}/PayButton.mp3`} />
      <div onClick={playPaySound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>$</RetroSquareButton>
      </div>
    </>
  );
};

// Buttons with specific content and links
const LeftButton = () => {
  const wheelAudioRef = useRef(null);

  const playWheelSound = (e) => {
    e.preventDefault(); // Prevent single click from navigating
    if (wheelAudioRef.current) {
      wheelAudioRef.current.currentTime = 0;
      wheelAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playWheelSound(e);
    window.open('https://wheel.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={wheelAudioRef} src={`${AUDIO_BASE_URL}/WheelButton.mp3`} />
      <div onClick={playWheelSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ top: '40px', left: '20px', position: 'fixed' }}>!</RetroSquareButton>
      </div>
    </Box>
  );
};

const RightButton = () => {
  const tanksAudioRef = useRef(null);

  const playTanksSound = (e) => {
    e.preventDefault();
    if (tanksAudioRef.current) {
      tanksAudioRef.current.currentTime = 0;
      tanksAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playTanksSound(e);
    window.open('https://tanks.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={tanksAudioRef} src={`${AUDIO_BASE_URL}/TanksButton.mp3`} />
      <div onClick={playTanksSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ top: '40px', right: '20px', position: 'fixed' }}>?</RetroSquareButton>
      </div>
    </Box>
  );
};

// Add this code below your existing LeftButton and RightButton components
const BottomLeftButton = () => {
  const flipAudioRef = useRef(null);

  const playFlipSound = (e) => {
    e.preventDefault();
    if (flipAudioRef.current) {
      flipAudioRef.current.currentTime = 0;
      flipAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }
  };

  const handleDoubleClick = (e) => {
    playFlipSound(e);
    window.open('https://flip.satoshiadvance.com', '_blank');
  };

  return (
    <Box sx={{ position: 'fixed', zIndex: 100000 }}>
      <audio ref={flipAudioRef} src={`${AUDIO_BASE_URL}/FlipButton.mp3`} />
      <div onClick={playFlipSound} onDoubleClick={handleDoubleClick}>
        <RetroSquareButton sx={{ bottom: '20px', left: '20px', position: 'fixed' }}>B</RetroSquareButton>
      </div>
    </Box>
  );
};

const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF', // White lettering
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  fontWeight: 'bold',
  fontSize: '2.4rem',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive",
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

const RetroIconButton = styled(motion(IconButton))(({ theme }) => ({
  color: '#FFFFFF',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  fontSize: '4rem',
  marginRight: '20px',
  '& .MuiSvgIcon-root': {
    fontSize: '4rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
  },
}));

export const DividerBar = styled(motion('div'))(({ theme }) => ({
  width: '80%',
  height: '4px',
  backgroundColor: '#FFFFFF',
  margin: '20px auto 0',
  marginBottom: '30px',
  transformOrigin: 'center',
  boxShadow: `
    0 0 10px #FFFFFF,
    0 0 20px #00BFFF,
    0 0 30px #00BFFF,
    0 0 40px #00BFFF,
    0 0 70px #FFFFFF,
    0 0 80px #FFFFFF,
    0 0 100px #00BFFF,
    0 0 150px #00BFFF
  `,
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

export const MotionDivider = () => {
  return (
    <DividerBar
      initial={{ scaleX: 0 }}
      animate={{
        scaleX: 1,
        rotateX: 15,
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
      }}
    />
  );
};

const EmailInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '1rem',
    color: '#FFFFFF',
    backgroundColor: '#283593',
    borderRadius: '4px',
    width: '562.5px',
    height: '40px',
    boxSizing: 'border-box',
    padding: '10px 14px',
    border: '2px solid #FFFFFF',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#3949ab',
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused': {
      borderColor: '#FFFFFF',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    padding: '10px 0',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '95vw',
    '& .MuiOutlinedInput-root': {
      width: '100%',
    }
  }
}));

const SignUpButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1rem',
  color: '#FFFFFF',
  backgroundColor: '#283593',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '10px 20px',
  marginLeft: '0',
  textTransform: 'uppercase',
  height: '40px',
  '&:hover': {
    backgroundColor: '#1a237e',
    borderColor: '#FFFFFF',
  },
  [theme.breakpoints.down('sm')]: {
    width: '120px',
    fontSize: '0.8rem',
    padding: '8px 16px',
    margin: '0 auto',
  }
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '80%',
  borderBottom: '1px solid #fff',
  height: '30px',
  position: 'fixed',
  top: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  backgroundColor: '#283593',
  paddingTop: '5px',
  padding: '15px 20px 10px 20px',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    padding: '15px 10px 10px 10px'
  }
}));

const HeaderTitle = styled(Typography)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '22px',
  color: '#fff',
  marginBottom: '2px',
});

const HeaderLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  marginBottom: '2px',
  cursor: 'pointer',
  '&:hover': {
    color: '#ccc',
  },
});

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  borderTop: '1px solid #fff',
  height: '30px',
  backgroundColor: '#283593',
  padding: '5px 10px',
  [theme.breakpoints.down('sm')]: {
    width: '90%'
  }
}));

const FooterLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  '&:hover': {
    color: '#ccc',
  },
});

const PriceDisplay = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [price, setPrice] = useState(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [playlist] = useState([
    `${AUDIO_BASE_URL}/retrodisco.wav`,
    `${AUDIO_BASE_URL}/retrolightning.wav`,
    `${AUDIO_BASE_URL}/retrotrance.wav`,
    `${AUDIO_BASE_URL}/retrogame8.wav`,
    `${AUDIO_BASE_URL}/UpbeatRetroIntro.wav`,
    `${AUDIO_BASE_URL}/DogTunes7.wav`,
    `${AUDIO_BASE_URL}/DogTunes1.wav`,
    `${AUDIO_BASE_URL}/DogTunes2.wav`,
    `${AUDIO_BASE_URL}/DogTunes3.wav`,
    `${AUDIO_BASE_URL}/DogTunes4.wav`,
    `${AUDIO_BASE_URL}/DogTunes5.wav`,
    `${AUDIO_BASE_URL}/DogTunes6.wav`,
    `${AUDIO_BASE_URL}/DogTunes8.wav`,
    `${AUDIO_BASE_URL}/DogTunes9.wav`,
    `${AUDIO_BASE_URL}/DogTunes10.wav`,
    `${AUDIO_BASE_URL}/DogTunes11.mp3`,
  ]);
  const audioRef = useRef(null);
  const aboutAudioRef = useRef(null);
  const nextButtonAudioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Volume state & toggle
  const [volume, setVolume] = useState(1);
  const toggleVolume = () => {
    if (volume === 1) {
      setVolume(0.33);
    } else {
      setVolume(1);
    }
  };

  // For TTS (speak once) & fade logic
  const [hasSpoken, setHasSpoken] = useState(false);


  const fadeInMusic = () => {
    if (!audioRef.current) return;
  
    // Start at volume 0
    audioRef.current.volume = 0;
  
    let stepCount = 30; // 30 steps total
    let currentStep = 0;
    const fadeInterval = setInterval(() => {
      currentStep++;
      // Multiply by 0.75 so it never exceeds 75% volume
      const newVolume = ((currentStep / stepCount) * 0.75).toFixed(2); 
      audioRef.current.volume = newVolume;
  
      if (currentStep >= stepCount) {
        clearInterval(fadeInterval);
      }
    }, 1000); // 1 step per second => 30s total
  };

  // Read ticker text once using Web Speech API
  const readTickerAloud = () => {
    if (!('speechSynthesis' in window)) {
      console.warn("This browser doesn't support speechSynthesis.");
      return;
    }
    
    const utterance = new SpeechSynthesisUtterance(TICKER_TEXT);
    utterance.rate = 1;
    utterance.pitch = 1;
    utterance.volume = 0.75; // TTS volume unaffected by music volume
  
    // 2) On TTS end, wait 5 seconds, then reduce music volume to 30%
    utterance.onend = () => {
      setTimeout(() => {
        setVolume(0.3); 
        // Or any volume level you'd like (0.3 = 30% of full).
      }, 5000);
    };
  
    speechSynthesis.speak(utterance);
  };

  const [emailValid, setEmailValid] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // For payment popup

  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    loanAmount: '',
    useOfFunds: '',
    country: ''
  });

  const [showWelcome, setShowWelcome] = useState(false);
  const [userData, setUserData] = useState(() => {
    const saved = localStorage.getItem('user');
    return saved ? JSON.parse(saved) : null;
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [loginTab, setLoginTab] = useState('login');

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    sessionStorage.removeItem('userEmail');
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');

    setUserData(null);
    handleMenuClose();

    const logoutSound = new Audio(`${AUDIO_BASE_URL}/retroshot.wav`);
    logoutSound.play().catch(error => console.log('Error playing sound:', error));

    window.location.reload();
  };

  // Modified toggleMusic to fade in and do TTS on first play
  const toggleMusic = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      // Pause if already playing
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      // Start playing
      audioRef.current.load();
      audioRef.current.play()
        .then(() => {
          setIsPlaying(true);

          // Fade in from 0 to full volume the *first* time
          // Only do fade if user is turning music ON from a paused state
          fadeInMusic();

          // Trigger TTS once
          if (!hasSpoken) {
            readTickerAloud();
            setHasSpoken(true);
          }
        })
        .catch((error) => {
          console.log('Error playing audio:', error);
        });
    }
  };

  const skipTrack = () => {
    const nextIndex = currentTrackIndex < playlist.length - 1 ? currentTrackIndex + 1 : 0;
    setCurrentTrackIndex(nextIndex);
  };

  // Updated track ending handler
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = playlist[currentTrackIndex];
      if (isPlaying) {
        audioRef.current.load();
        audioRef.current.play()
          .catch(error => console.log('Error playing audio:', error));
      }
    }
  }, [currentTrackIndex, playlist, isPlaying]);

  // Keep audioRef in sync with volume toggles
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        // Determine the base URL based on environment
        const baseURL = window.location.hostname === 'localhost' 
          ? 'http://localhost:5000'  // Local development
          : 'https://satoshi-homepage-59650ad0a3ce.herokuapp.com'; // Production Heroku URL
    
        console.log('Fetching BTC price from:', baseURL);
        
        const response = await axios.get(`${baseURL}/api/btc-price`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
    
        if (response.data?.success && response.data?.price) {
          const formattedPrice = parseFloat(response.data.price).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          });
          setPrice(formattedPrice);
          console.log('Successfully set BTC price:', formattedPrice);
        } else {
          throw new Error('Invalid price data received');
        }
      } catch (error) {
        console.error('Error fetching BTC price:', error.message);
        setPrice('Price Unavailable');
        
        // Detailed error logging
        if (error.response) {
          console.error('Error response:', {
            status: error.response.status,
            data: error.response.data
          });
        }
      }
    };

    fetchBTCPrice();
    const interval = setInterval(fetchBTCPrice, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // If there's a hash, scroll to it after a short delay
    if (window.location.hash) {
      const anchorId = window.location.hash.substring(1);
      setTimeout(() => {
        const anchorElement = document.getElementById(anchorId);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 800);
    }
  }, []);

  // Autoplay attempt: pick a random track
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * playlist.length);
    setCurrentTrackIndex(randomIndex);

    // Attempt autoplay
    if (audioRef.current) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.log('Autoplay failed:', error);
      });
    }
  }, []);

  useEffect(() => {
    const shouldShowWelcome = localStorage.getItem('showWelcomeNotification');
    if (shouldShowWelcome === 'true') {
      setShowWelcome(true);
      localStorage.removeItem('showWelcomeNotification');

      const audio = new Audio(`${AUDIO_BASE_URL}/retroshot.wav`);
      audio.play().catch(error => console.log('Error playing sound:', error));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nextButtonAudioRef.current) {
      nextButtonAudioRef.current.currentTime = 0;
      nextButtonAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
    }

    if (formStep === 1) {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setEmailValid(false);
        return;
      }
      setEmailValid(true);
      setFormStep(2);
    } else {
      try {
        await axios.post('/api/signup', formData);
        alert('Thank you for signing up!');
        setFormStep(1);
        setFormData({
          email: '',
          loanAmount: '',
          useOfFunds: '',
          country: ''
        });
      } catch (error) {
        console.error('Error signing up:', error);
        alert('Failed to sign up. Please try again.');
      }
    }
  };

  const openPaymentPopup = () => {
    setIsPopupOpen(true);
  };

  const closePaymentPopup = () => {
    setIsPopupOpen(false);
  };

  const scrollToDogSprite = (e) => {
    e.preventDefault();
    const element = document.getElementById('dogSprite');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        backgroundColor: '#283593',
        padding: '45px 20px 50px 20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowX: 'hidden',
        maxWidth: '100vw',
      }}
    >
      <HeaderContainer>
        <HeaderLink>Stats</HeaderLink>
        <HeaderTitle>Satoshi Advance</HeaderTitle>
        {userData ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  backgroundColor: '#283593',
                  border: '1px solid #FFFFFF',
                  '& .MuiMenuItem-root': {
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: '19px',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'
                    }
                  }
                }
              }}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <HeaderLink
              onClick={handleMenuClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}
            >
              {userData.email}
              <ArrowDropDownIcon />
            </HeaderLink>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HeaderLink
              onClick={() => {
                setIsSignUpOpen(true);
                setLoginTab('login');
              }}
              sx={{
                marginBottom: '2px',
                '&:hover': { color: '#ccc' }
              }}
            >
              Login
            </HeaderLink>
            <Separator>/</Separator>
            <HeaderLink
              onClick={() => {
                setIsSignUpOpen(true);
                setLoginTab('signup');
              }}
              sx={{
                marginBottom: '2px',
                '&:hover': { color: '#ccc' }
              }}
            >
              Sign Up
            </HeaderLink>
          </Box>
        )}
      </HeaderContainer>

      <TickerTape>
        <TickerContent>
          Welcome SatoshiAdvance - Crypto Loans, news, events and more!    •
          Put on headphones & Explore
          BTC All Time High $108,800 January, 2025!    •
          Borrow, , Lend, Shop, & more in DOGE, BTC & ETH    •
          ETH All Time High $4,7250 November, 2021!     •
          DOGE All time High $0.74 May, 2021!    •
          As of December 2024, 10,000 Satoshi's are worth about $10 USD!   •
          Play Dog Cards! learn about dog breeds & download your favorite card <a href="#" onClick={scrollToDogSprite}>Here</a>! •
        </TickerContent>

      </TickerTape>

      {showWelcome && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setShowWelcome(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Box
            sx={{
              backgroundColor: '#1a237e',
              color: '#FFFFFF',
              padding: '16px',
              borderRadius: '4px',
              border: '2px solid #FFFFFF',
              fontFamily: "'Press Start 2P', cursive",
              fontSize: '0.8rem',
              boxShadow: `
                0 0 10px #FFFFFF,
                0 0 20px #00BFFF,
                0 0 30px #00BFFF
              `,
            }}
          >
            Account setup complete! Welcome to Satoshi Advance
          </Box>
        </Snackbar>
      )}

      <LeftButton />
      <RightButton />
      <BottomLeftButton />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          marginTop: '80px',
        }}
      >
        <RetroIconButton
          onClick={toggleMusic}
          initial={{ opacity: 0, x: -50, rotate: 0, y: 0 }}
          animate={{ opacity: 1, x: 0, rotate: [0, 360, 0], y: [0, -10, 0] }}
          transition={{
            opacity: { duration: 1 },
            x: { duration: 1 },
            rotate: {
              duration: 1.5,
              ease: 'linear',
              times: [0, 0.9, 1],
              repeat: Infinity,
              repeatDelay: 15,
            },
            y: {
              duration: 0.5,
              ease: 'easeInOut',
              times: [0, 0.9, 1],
              repeat: Infinity,
              repeatDelay: 15,
            },
          }}
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </RetroIconButton>

        <RetroText
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 1 }}
        >
          {price || '$0.00'}
        </RetroText>

        <RetroIconButton
          onClick={skipTrack}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 1 }}
        >
          <SkipNextIcon />
        </RetroIconButton>

        {/* Volume Toggle Button */}
        <RetroIconButton
          onClick={toggleVolume}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 1 }}
        >
          {volume === 1 ? (
            <VolumeUpIcon
              sx={{
                fontSize: '3rem',
                color: '#FFFFFF',
                filter: 'drop-shadow(0 0 5px #00BFFF) drop-shadow(0 0 10px #00BFFF)'
              }}
            />
          ) : (
            <VolumeDownIcon
              sx={{
                fontSize: '3rem',
                color: '#FFFFFF',
              }}
            />
          )}
        </RetroIconButton>
      </Box>

      <MotionDivider />

      <Box
        sx={{
          width: '100%',
          padding: '40px 0',
          backgroundColor: '#1a237e',
          marginY: '40px',
          borderRadius: '10px',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)'
        }}
      >
        <LenderBorrowerApply />
      </Box>

      <audio ref={audioRef} src={playlist[currentTrackIndex]} />
      <audio ref={nextButtonAudioRef} src={`${AUDIO_BASE_URL}/retroshot.wav`} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: {
            xs: '0',
            sm: '0',
            md: '-0.42in'
          },
          width: '100%',
          '& > *': {
            width: { xs: '95%', md: '100vw' },
            maxWidth: '2900px',
          },
        }}
      >
        <TopCryptoLoans />
      </Box>

      <UsdVsBtcComparison />
      <PopularLinks />
      <UsdToBtcConverter />
      <EducationLinks />

      <NewsAndBlogs />
      <PlayCoinGames />

      <PaymentButton onClick={openPaymentPopup}>$</PaymentButton>
      {isPopupOpen && <PaymentPopup onClose={closePaymentPopup} />}

      <Box id="dogSprite" sx={{ scrollMargin: '100px' }}>
        <DogSprite />
      </Box>

      <FooterContainer>
        <audio ref={aboutAudioRef} src={`${AUDIO_BASE_URL}/retroshot.wav`} />
        <FooterLink
          component="div"
          onClick={(e) => {
            e.preventDefault();
            if (aboutAudioRef.current) {
              aboutAudioRef.current.currentTime = 0;
              aboutAudioRef.current.play().catch(error => console.log('Error playing sound:', error));
            }
          }}
          onDoubleClick={() => window.location.href = '/about'}
          sx={{ cursor: 'pointer' }}
        >
          About
        </FooterLink>
      </FooterContainer>

      {isSignUpOpen && <AuthPopup onClose={() => setIsSignUpOpen(false)} />}
    </Box>
  );
};

export default PriceDisplay;
